import {
  useAlphaDetailInfoChartQuery,
  useAlphaDetailInfoStyleQuery,
  useAlphaDetailInfoSummaryQuery,
} from "hooks/queries/useAlphaTraderDetailQuery";
import { useEffect } from "react";
import styled from "styled-components";
import InfoStyleRetSection from "./InfoStyleRetSection";
import InfoStyleScatterSection from "./InfoStyleScatterSection";
import { InfoStyleSection } from "./InfoStyleSection";
import InfoSummarySection from "./InfoSummarySection";

const DetailWrapper = styled.div`
  color: #141414;
  font-family: Pretendard;

  .title-section {
    margin: 40px 0 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 23.87px;
      text-align: left;
    }

    .info {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.32px;
      text-align: right;
      color: #afb0b2;
    }

    .title-date {
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.71px;
      text-align: center;

      color: #afb0b2;
      margin-left: 6px;
    }
  }
`;

const SummaryWrapper = styled.div`
  width: 100%;
  height: 530px;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #f5f1f0;
  border: 1px solid #ebe8e8;
  border-radius: 16px;
`;

const AlphaDetailInfo = ({ cuid, lastUpdate = "" }) => {
  const {
    data: summaryData,
    isLoading: summaryLoading,
    refetch: summaryRefetch,
  } = useAlphaDetailInfoSummaryQuery({
    cuid: cuid,
  });

  const {
    data: styleData,
    isLoading: styleLoading,
    refetch: styleRefetch,
  } = useAlphaDetailInfoStyleQuery({
    cuid: cuid,
  });
  const {
    data: chartData,
    isLoading: chartLoading,
    refetch: chartRefetch,
  } = useAlphaDetailInfoChartQuery({
    cuid: cuid,
  });

  useEffect(() => {
    // console.log("AlphaDetailInfo cuid", cuid);
  }, [cuid]);

  return (
    <DetailWrapper>
      <div className="title-section">
        <div className="title">
          요약<span className="title-date">{lastUpdate} 기준</span>
        </div>
        <div className="info">
          *알파노트에서 가공한 데이터는 실제 데이터와 차이가 있을 수 있습니다.
        </div>
      </div>
      {summaryData && !summaryLoading && (
        <InfoSummarySection data={summaryData} lastUpdate={lastUpdate} />
      )}
      {styleData && !styleLoading && <InfoStyleSection data={styleData} />}
      {styleData && !styleLoading && <InfoStyleRetSection data={styleData} />}
      {chartData && !chartLoading && (
        <InfoStyleScatterSection data={chartData} />
      )}
    </DetailWrapper>
  );
};

export default AlphaDetailInfo;
