import React from "react";
import styled from "styled-components";

const BackgroundWrapper = styled.div`
  background-color: #f0f2f5;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  color: #000;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  text-align: left;
`;

const Title = styled.h1`
  font-family: Pretendard;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const Paragraph = styled.p`
  font-family: Pretendard;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
`;

const TermsOfServicePage = () => {
  const termsContent = [
    {
      title: "서비스 이용 약관",
      content:
        "본 이용약관은 [회사명] (이하 '회사')이 제공하는 모든 서비스(이하 '서비스')의 이용 조건과 절차, 회사와 회원 간의 권리, 의무 및 책임사항 등을 규정합니다.",
    },
    {
      title: "회원가입 및 서비스 이용",
      content:
        "서비스 이용을 위해서는 회원가입이 필요합니다. 회원가입 시 제공한 정보는 정확하고 최신의 정보여야 하며, 변경 시 즉시 수정해야 합니다.",
    },
    {
      title: "개인정보 보호",
      content:
        "회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의 개인정보 처리방침이 적용됩니다.",
    },
    {
      title: "서비스 제공 및 변경",
      content:
        "회사는 회원에게 안정적인 서비스 제공을 위해 최선을 다하며, 서비스의 내용이 변경되는 경우 사전에 공지합니다.",
    },
    {
      title: "이용제한 및 계약해지",
      content:
        "회사는 회원이 이용약관을 위반하거나 서비스의 정상적인 운영을 방해한 경우, 서비스 이용을 제한하거나 회원과의 이용계약을 해지할 수 있습니다.",
    },
  ];

  return (
    <BackgroundWrapper>
      <ContentWrapper>
        <Title>이용약관</Title>

        {termsContent.map((term, index) => (
          <Section key={index}>
            <SectionTitle>{`${index + 1}. ${term.title}`}</SectionTitle>
            <Paragraph>{term.content}</Paragraph>
          </Section>
        ))}
      </ContentWrapper>
    </BackgroundWrapper>
  );
};

export default TermsOfServicePage;
