import { useEffect, useState } from "react";

export const useIntersectionObserver = ({
  threshold = 0.5,
  hasNextPage,
  fetchNextPage,
}) => {
  // 관찰할 요소입니다. 스크롤 최하단 div요소에 setTarget을 ref로 넣어 사용할 것입니다.
  const [target, setTarget] = useState(null);

  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      // target이 화면에 관찰되고, 다음페이지가 있다면 다음페이지를 호출
      if (entry.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    });
  };

  useEffect(() => {
    if (!target) return;

    const observer = new IntersectionObserver(observerCallback, { threshold });
    observer.observe(target);

    return () => {
      observer.disconnect();
    };
  }, [target, threshold, hasNextPage, fetchNextPage]);

  return setTarget;
};
