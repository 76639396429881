import { createChart } from "lightweight-charts";

import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { addCommaToInt, convertToNearWon, convertToWon } from "utils/utils";

import { VertLine } from "utils/vertical-line";

const ChartBackgroundWrapper = styled.div`
  display: flex;
  position: relative;
`;

const MALabelWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 12px;
`;

const MALabel = styled.span`
  color: ${(props) => props.color};
`;

var chartHeights = {
  candle: 270,
  volume: 50,
  asset: 50,
};
const timeLableHeight = 25;
const toolTipWidth = 120;

const ChartContainer = styled.div`
  position: relative;
  width: ${(props) => props.chartWidth}px;
  height: ${chartHeights.candle + 58}px;
`;

const watermarkCommon = {
  color: "#000",
  visible: true,
  fontFamily: "Pretendard",
  horzAlign: "left",
  vertAlign: "top",
};

const markerColor = {
  B: "rgba(0, 0, 0, 1)",
  S: "rgba(0, 0, 0, 1)",
  // B: "rgba(255, 165, 0, 1)",
  // S: "rgba(0, 128, 0, 1)",
  // B: "rgba(76, 91, 255, 1)",
  // S: "rgba(255, 39, 39, 1)",
};

const maStyle = {
  // 5, 20, 60, 120, 240
  5: {
    color: "rgba(255,152,207,1)", // pink
    width: 0.8,
  },
  20: {
    color: "rgba(98,180,255,1)", // lightblue
    width: 0.9,
  },
  60: {
    color: "rgba(254,183,3,1)", // yellow
    width: 1.0,
  },
  120: {
    color: "rgba(0,150,33,1)", // green
    width: 1.1,
  },
  240: {
    color: "rgba(142,69,177,1)", // purple
    width: 1.2,
  },
};

export const AlphaChart = ({
  chartData,
  markersData,
  mode = "",
  name = "",
  maShow = true,
  chartWidth = 1072,
}) => {
  // const containerRef = useRef();
  const [currentType, setCurrentType] = useState("1min");
  const [chartSetup, setChartSetup] = useState(null);

  const containerRef = useRef();
  const [candleChart, setCandleChart] = useState(null);
  const [volumeChart, setVolumeChart] = useState(null);
  const [assetChart, setAssetChart] = useState(null);
  const [candleSeries, setCandleSeries] = useState(null);
  const [volumeSeries, setVolumeSeries] = useState(null);
  const [cMarkerSeriesList, setCMarkerSeriesList] = useState([]);
  const [vMarkerSeriesList, setVMarkerSeriesList] = useState([]);

  const cMarkerSeries = useRef([]);
  const vMarkerSeries = useRef([]);

  const maLineSeries = useRef();

  const cLineSeries = useRef();
  const vLineSeries = useRef();
  const aLineSeries = useRef();

  const [holdSeries, setHoldSeries] = useState(null);
  const [evalSeries, setEvalSeries] = useState(null);

  const toolTipHeight =
    mode === "extra"
      ? chartHeights.candle + chartHeights.volume + chartHeights.asset
      : chartHeights.candle + chartHeights.volume;
  // const createToolTip = (container) => {
  //   const toolTip = document.createElement('div');
  //   toolTip.style = `width: ${toolTipWidth}px; height: ${toolTipHeight}px; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border-radius: 4px 4px 0px 0px; border-bottom: none; box-shadow: 0 2px 5px 0 rgba(117, 134, 150, 0.45);font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
  //   toolTip.style.background = `rgba(${'255, 255, 255'}, 0.25)`;
  //   toolTip.style.color = 'black';
  //   toolTip.style.borderColor = 'rgba( 239, 83, 80, 1)';
  //   container.appendChild(toolTip);
  //   return toolTip;
  // }

  const candle = chartData?.candle;
  const volume = chartData?.volume;
  const markers = markersData;
  const n_hold = chartData?.n_hold;
  const evalAsset = chartData?.pnl;
  const n_agg = chartData?.n_agg;
  const code = chartData?.code;
  // const toolTip = createToolTip(container)
  // container.appendChild(toolTip);

  const ma = chartData?.ma;

  const CANDLE_OPTION = {
    width: chartWidth,
    layout: {
      textColor: "#AFB0B2",
      background: { type: "solid", color: "transparent" },
    },
    grid: {
      vertLines: { color: "#DCDEE3" },
      horzLines: { color: "#DCDEE3" },
    },
    timeScale: {
      visible: false,
    },
    localization: {
      priceFormatter: (price) => {
        return convertToWon(price);
      },
    },
    watermark: {
      ...watermarkCommon,
      fontSize: 14,
      text:
        n_agg < 60
          ? `alphanote.io ${name} ${n_agg}분봉`
          : n_agg < 1440
          ? `alphanote.io ${name} ${n_agg / 60}시간봉`
          : `alphanote.io ${name} ${n_agg / 1440}일봉`,
    },
    crosshair: {
      vertLine: {
        color: "#B7C3F3",
        width: 1,
        style: 1,
        visible: true,
        labelVisible: true,
      },
      horzLine: {
        color: "#B7C3F3",
        width: 1,
        style: 1,
        visible: true,
        labelVisible: true,
      },
      mode: 0,
    },
    rightPriceScale: {
      minimumWidth: 100,
    },
  };
  const VOLUME_OPTION = {
    width: chartWidth,
    layout: {
      textColor: "#AFB0B2",
    },
    grid: {
      vertLines: { color: "#DCDEE3" },
      horzLines: { color: "#DCDEE3" },
    },
    timeScale: {
      visible: mode === "extra" ? false : true,
    },
    localization: {
      priceFormatter: (amt) => {
        return amt === 0 ? `0원` : `${addCommaToInt(amt.toFixed(0))}억원`;
      },
    },
    watermark: {
      ...watermarkCommon,
      fontSize: 12,
      text: `거래대금`,
    },
    crosshair: {
      vertLine: {
        color: "#B7C3F3",
        width: 1,
        style: 1,
        visible: true,
        labelVisible: true,
      },
      horzLine: {
        color: "#B7C3F3",
        width: 1,
        style: 1,
        visible: true,
        labelVisible: true,
      },
      mode: 0,
    },
    layout: {
      background: {
        type: "solid",
        color: "transparent",
      },
    },
    rightPriceScale: {
      minimumWidth: 100,
    },
  };
  const ASSET_OPTION = {
    width: chartWidth,
    layout: {
      textColor: "#AFB0B2",
    },
    grid: {
      vertLines: { color: "#DCDEE3" },
      horzLines: { color: "#DCDEE3" },
    },
    timeScale: {
      visible: mode === "extra" ? true : false,
    },
    localization: {
      priceFormatter: (price) => {
        return convertToNearWon(price);
      },
    },
    watermark: {
      ...watermarkCommon,
      fontSize: 12,
      // text: `평가자산 & 비중`,
      text: `비중`,
    },
    rightPriceScale: {
      minimumWidth: 100,
    },
  };

  const VerticalLineOption = {
    width: 1,
    color: "red",
  };

  const myChartResize = (candleInstance, volumeInstance, assetInstance) => {
    var largestPriceScaleWdith =
      mode === "extra"
        ? Math.min(
            candleInstance.priceScale("right").width(),
            volumeInstance.priceScale("right").width(),
            assetInstance.priceScale("right").width()
          )
        : Math.max(
            candleInstance.priceScale("right").width(),
            volumeInstance.priceScale("right").width()
          );

    var targetWidth =
      chartWidth -
      (largestPriceScaleWdith - candleInstance.priceScale("right").width());

    candleInstance.resize(targetWidth, chartHeights.candle);
    targetWidth =
      chartWidth -
      (largestPriceScaleWdith - volumeInstance.priceScale("right").width());

    volumeInstance.resize(
      targetWidth,
      mode === "extra"
        ? chartHeights.volume
        : chartHeights.volume + timeLableHeight
    );
    targetWidth =
      chartWidth -
      (largestPriceScaleWdith - assetInstance.priceScale("right").width());

    assetInstance.resize(
      targetWidth,
      mode === "extra" ? chartHeights.asset + timeLableHeight : 0
    );
  };

  // console.log(mode);
  const createToolTip = (container) => {
    const toolTip = document.createElement("div");
    toolTip.style = `width: ${toolTipWidth}px; height: ${toolTipHeight}px; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border-radius: 4px 4px 0px 0px; border-bottom: none; box-shadow: 0 2px 5px 0 rgba(117, 134, 150, 0.45);font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
    toolTip.style.background = `rgba(${"255, 255, 255"}, 0.25)`;
    toolTip.style.color = "black";
    toolTip.style.borderColor = "rgba( 239, 83, 80, 1)";
    container.appendChild(toolTip);
    return toolTip;
  };

  useEffect(() => {
    const candleInstance = createChart(containerRef.current, CANDLE_OPTION);
    candleInstance.priceScale("right").applyOptions({
      scaleMargins: {
        top: 0.05,
        bottom: 0.05,
      },
    });

    const volumeInstance = createChart(containerRef.current, VOLUME_OPTION);
    volumeInstance.priceScale("right").applyOptions({
      scaleMargins: {
        top: 0,
        bottom: 0,
      },
    });

    const assetInstance = createChart(containerRef.current, ASSET_OPTION);

    if (assetInstance) {
      assetInstance.timeScale().fitContent();
      const holdSeries = assetInstance.addLineSeries({
        color: "#B19CD9",
        lastValueVisible: false,
        crosshairMarkerVisible: true,
        lineWidth: 3,
        // follow left price scale
        priceScaleId: "left",
      });
      const eavlSeries = assetInstance.addLineSeries({
        color: "#4CAF50",
        lastValueVisible: false,
        crosshairMarkerVisible: true,
        lineWidth: 3,
        // follow right price scale
        priceScaleId: "right",
      });
    }

    const candleSeries = candleInstance.addCandlestickSeries();
    candleSeries.applyOptions({
      upColor: "#FF2727",
      wickUpColor: "#FF2727",
      downColor: "#4C67FF",
      wickDownColor: "#4C67FF",
      priceLineVisible: false,
      borderVisible: false,
      lastValueVisible: false,
    });

    const volumeSeries = volumeInstance.addHistogramSeries({
      color: "#26a69a",
      priceLineVisible: false,
      lastValueVisible: false,
      priceScaleId: "right",
    });

    const holdSeriesnew = assetInstance.addLineSeries({
      color: "rgba(33, 150, 243, .8)", // blue
      lastValueVisible: false,
      crosshairMarkerVisible: false,
      lineWidth: 2,
      lineStyle: 0,
      // follow left price scale
      priceScaleId: "left",
    });

    // const evalSeriesnew = assetInstance.addLineSeries({
    //   color: "rgba(40, 167, 69, 1)", // green
    //   lastValueVisible: false,
    //   crosshairMarkerVisible: true,
    //   lineWidth: 2,
    //   // follow right price scale
    //   priceScaleId: "right",
    // });

    // 차트 시간축 동기화
    candleInstance
      .timeScale()
      .subscribeVisibleLogicalRangeChange((timeRange) => {
        myChartResize(candleInstance, volumeInstance, assetInstance);

        if (timeRange) {
          volumeInstance.timeScale().setVisibleLogicalRange(timeRange);
          if (mode === "extra")
            assetInstance.timeScale().setVisibleLogicalRange(timeRange);
        }
      });
    volumeInstance
      .timeScale()
      .subscribeVisibleLogicalRangeChange((timeRange) => {
        myChartResize(candleInstance, volumeInstance, assetInstance);
        if (timeRange) {
          candleInstance.timeScale().setVisibleLogicalRange(timeRange);
          if (mode === "extra")
            assetInstance.timeScale().setVisibleLogicalRange(timeRange);
        }
      });

    if (mode === "extra") {
      assetInstance
        .timeScale()
        .subscribeVisibleLogicalRangeChange((timeRange) => {
          myChartResize(candleInstance, volumeInstance, assetInstance);
          if (timeRange) {
            candleInstance.timeScale().setVisibleLogicalRange(timeRange);
            volumeInstance.timeScale().setVisibleLogicalRange(timeRange);
          }
        });
    }

    candleInstance.timeScale().applyOptions({
      timeVisible: true,
      secondsVisible: true,
    });
    volumeInstance.timeScale().applyOptions({
      timeVisible: true,
      secondsVisible: true,
    });
    assetInstance.timeScale().applyOptions({
      timeVisible: true,
      secondsVisible: true,
    });

    setCandleChart(candleInstance);
    setVolumeChart(volumeInstance);
    setAssetChart(assetInstance);

    setCandleSeries(candleSeries);
    setVolumeSeries(volumeSeries);

    // setCMarkerSeriesList(cMarkerSeriesListnew);
    // setVMarkerSeriesList(vMarkerSeriesListnew);

    setHoldSeries(holdSeriesnew);
    // setEvalSeries(evalSeriesnew);

    const toolTip = createToolTip(containerRef.current);
    containerRef.current.appendChild(toolTip);

    candleInstance.subscribeCrosshairMove(function (param) {
      if (!cMarkerSeries) return;
      if (!param.time || param.seriesData.size === 0) {
        toolTip.style.display = "none";
        return;
      }

      // console.log("param", param);
      let candleData = {};
      let maindex = 0;
      const maindexkey = ["ma5", "ma20", "ma60", "ma120", "ma240"];

      param?.seriesData?.forEach((data) => {
        // Access each key-value pair in the seriesData object

        Object.entries(data).forEach(([key, value]) => {
          // console.log("!Key", key, value);
          if (key === "time") {
            chartData?.markers?.forEach((marker) => {
              if (marker.time === value) candleData["marker"] = true;
            });
          }
          if (key === "value") {
            candleData[maindexkey[maindex++]] = value;
          } else {
            candleData[key] = value;
          }
        });
      });
      // param?.seriesData?.forEach((key, value) => {
      //   console.log("value", value);
      // });
      // console.log("candle", param.point);

      if (candleData) {
        toolTip.innerHTML =
          (candleData?.customValues?.marker
            ? `<div>${
                candleData?.time
                  ? new Date(candleData.time * 1000)
                      .toISOString()
                      .slice(5, 16)
                      .replace("T", " ")
                  : ""
              } | ${candleData?.customValues?.value}원 |  ${
                candleData?.customValues?.amt + "원 "
              }${
                candleData?.customValues?.marker === "sell"
                  ? "매도"
                  : candleData?.customValues?.marker === "buy"
                  ? "매수"
                  : "홀딩"
              }</div>`
            : ``) +
          // show utc time with formmat 'MM-dd HH:mm:ss'
          (candleData?.time
            ? `<div>${new Date(candleData.time * 1000)
                .toISOString()
                .slice(5, 16)
                .replace("T", " ")}</div>`
            : ``) +
          (candleData?.open
            ? `<div>시가: ${candleData?.open.toFixed(0)}</div>`
            : ``) +
          (candleData?.high
            ? `<div>고가: ${candleData?.high.toFixed(0)}</div>`
            : ``) +
          (candleData?.low
            ? `<div>저가: ${candleData?.low.toFixed(0)}</div>`
            : ``) +
          (candleData?.close
            ? `<div>종가: ${candleData?.close.toFixed(0)}</div>`
            : ``) +
          (candleData?.customValues?.volume
            ? `<div>거래대금: ${candleData?.customValues?.volume.toFixed(
                0
              )}</div>`
            : ``) +
          `<div></div>` +
          `<div>이평선</div>` +
          (candleData?.ma5
            ? `<div>5: ${candleData.ma5.toFixed(0)}</div>`
            : ``) +
          (candleData?.ma20
            ? `<div>20: ${candleData.ma20.toFixed(0)}</div>`
            : ``) +
          (candleData?.ma60
            ? `<div>60: ${candleData.ma60.toFixed(0)}</div>`
            : ``) +
          (candleData?.ma120
            ? `<div>120: ${candleData.ma120.toFixed(0)}</div>`
            : ``) +
          (candleData?.ma240
            ? `<div>240: ${candleData.ma240.toFixed(0)}</div>`
            : ``);

        // `<div>대금: ${volumeData ? volumeData.value.toFixed(0) : 'N/A'}</div>`;

        let left = param.point.x - toolTipWidth / 2; // relative to timeScale

        toolTip.style.left = left + "px";
        toolTip.style.top = 0 + "px";
        toolTip.style.display = "block";
      } else {
        toolTip.style.display = "none";
      }

      if (mode === "extra") {
      }
    });

    volumeInstance.subscribeCrosshairMove(function (param) {
      // console.log("param", param);
      // if (!cMarkerSeries) return;
      if (!param.time || param.seriesData.size === 0) {
        toolTip.style.display = "none";
        return;
      }

      let candleData = {};

      param?.seriesData?.forEach((data) => {
        if (data.hasOwnProperty("color")) {
          candleData = data;
        }
      });
      // param?.seriesData?.forEach((key, value) => {
      //   console.log("value", value);
      // });

      if (candleData) {
        toolTip.innerHTML =
          // show utc time with formmat 'MM-dd HH:mm:ss'
          `<div>${
            candleData?.time
              ? new Date(candleData.time * 1000)
                  .toISOString()
                  .slice(5, 16)
                  .replace("T", " ")
              : ""
          }</div>` +
          `<div></div>` +
          `<div>거래대금</div>` +
          `<div>거래대금: ${candleData.value}</div>`;

        // `<div>대금: ${volumeData ? volumeData.value.toFixed(0) : 'N/A'}</div>`;

        let left = param.point.x - toolTipWidth / 2; // relative to timeScale

        toolTip.style.left = left + "px";
        toolTip.style.top = 0 + "px";
        toolTip.style.display = "block";
      } else {
        toolTip.style.display = "none";
      }

      if (mode === "extra") {
      }
    });

    return () => {
      candleInstance.remove();
      volumeInstance.remove();
      assetInstance.remove();
    };
  }, []);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    if (chartData && containerRef.current && candleSeries && volumeSeries) {
      // 차트 좌우 길이 맞추기, 오른쪽 가격 표시 부분 넓이가 변할 때 차트 넓이를 조절
      if (chartData?.candle && chartData.volume) {
        candleChart.removeSeries(candleSeries);
        const ncandleSeries = candleChart.addCandlestickSeries();
        ncandleSeries.applyOptions({
          upColor: "#FF2727",
          wickUpColor: "#FF2727",
          downColor: "#4C67FF",
          wickDownColor: "#4C67FF",
          priceLineVisible: false,
          borderVisible: false,
          lastValueVisible: false,
        });

        ncandleSeries.setData(
          chartData?.candle.map((item) => ({
            ...item,
            customValues: { volume: item.volume },
          })) || []
        );
        setCandleSeries(ncandleSeries);

        // add moving average series
        // chartData.ma is dictionary with key as ma period and value as array of ma values

        if (maLineSeries?.current) {
          maLineSeries.current.forEach((series) => {
            candleChart.removeSeries(series);
          });
          maLineSeries.current = [];
        }

        if (maShow && chartData?.ma) {
          maLineSeries.current = Object.keys(chartData.ma).map((key) => {
            const series = candleChart.addLineSeries({
              color: maStyle[key].color,
              lineWidth: maStyle[key].width,
              priceLineVisible: false,
              lastValueVisible: false,
              priceScaleId: "right",
            });
            series.setData(chartData.ma[key]);
            return series;
          });
          // Object.keys(chartData.ma).forEach((key) => {
          //   maLineSeries.current = (
          //     candleChart.addLineSeries({
          //       color: maStyle[key].color,
          //       lineWidth: maStyle[key].width,
          //       priceLineVisible: false,
          //       lastValueVisible: false,
          //       priceScaleId: "right",
          //     })
          //   );
          //   maLineSeries.current[0].setData(chartData.ma[key]);
          // });
        }

        if (cLineSeries?.current) {
          candleChart.removeSeries(cLineSeries.current);
        }
        cLineSeries.current = candleChart.addLineSeries();

        chartData?.vert_lines?.forEach((line) => {
          const vertLine = new VertLine(
            candleChart,
            cLineSeries.current,
            line.timestamp, //여기에 하나하나 넣어주어야 함
            {
              ...VerticalLineOption,
              showLabel: false,
              labelText: "",
            }
          );
          cLineSeries.current.attachPrimitive(vertLine);
        });

        if (vLineSeries?.current) {
          volumeChart.removeSeries(vLineSeries.current);
        }

        vLineSeries.current = volumeChart.addLineSeries();

        chartData?.vert_lines?.forEach((line) => {
          const vertVolumeLine = new VertLine(
            volumeChart,
            vLineSeries.current,
            line.timestamp, //여기에 하나하나 넣어주어야 함
            {
              ...VerticalLineOption,
              showLabel: false,
              labelText: "",
            }
          );
          vLineSeries.current.attachPrimitive(vertVolumeLine);
        });

        aLineSeries.current = assetChart.addLineSeries();

        chartData?.vert_lines?.forEach((line) => {
          const vertAssetLine = new VertLine(
            assetChart,
            aLineSeries.current,
            line.timestamp, //여기에 하나하나 넣어주어야 함
            {
              ...VerticalLineOption,
              showLabel: false,
              labelText: "",
            }
          );
          aLineSeries.current.attachPrimitive(vertAssetLine);
        });

        volumeChart.removeSeries(volumeSeries);
        const nvolumeSeries = volumeChart.addHistogramSeries({
          color: "#26a69a",
          priceLineVisible: false,
          lastValueVisible: false,
          priceScaleId: "right",
        });

        nvolumeSeries.setData(chartData?.volume || []);
        setVolumeSeries(nvolumeSeries);
        if (chartData?.n_hold) holdSeries.setData(chartData?.n_hold || []);
        // if (chartData?.pnl) evalSeries.setData(chartData?.pnl || []);
      }

      cMarkerSeries.current?.forEach((series) => {
        try {
          candleChart.removeSeries(series);
        } catch (e) {
          console.log(e);
        }
      });

      cMarkerSeries.current =
        markersData?.map((marker, idx) => {
          const series = candleChart.addLineSeries({
            color: "rgba(0, 0, 0, 0)",
            lastValueVisible: false,
            crosshairMarkerVisible: false,
            priceLineVisible: false,
          });

          series.setData([
            {
              time: marker?.time,
              value: marker?.value,
              customValues: {
                marker: marker?.shape === "arrowUp" ? "buy" : "sell",
                amt: marker?.amt,
                value: marker?.value,
              },
            },
          ]);
          series.setMarkers([
            {
              time: marker.time,
              position: "inBar", //marker.position,
              // shape: marker.shape,
              shape: marker.shape === "arrowUp" ? "buy" : "sell",
              color: markerColor[marker.text],
              // text: marker.text,
              size: 1, // marker.size,
            },
          ]);

          return series;
        }) || null;

      vMarkerSeries.current?.forEach((series) => {
        try {
          volumeChart.removeSeries(series);
        } catch (e) {
          console.log(e);
        }
      });

      vMarkerSeries.current =
        markersData?.map((marker, idx) => {
          const series = volumeChart.addLineSeries({
            color: "rgba(0, 0, 0, 0)",
            lastValueVisible: false,
            crosshairMarkerVisible: false,
            priceLineVisible: false,
            priceScaleId: "left",
          });

          series.setData([{ time: marker?.time, value: 0 }]);
          series.setMarkers([
            {
              time: marker.time,
              position: marker.position,
              // shape: marker.shape,
              color: "rgba(0, 0, 0, 0)",
              // text: marker.text,
              size: marker.size,
            },
          ]);

          return series;
        }) || [];

      setCMarkerSeriesList(cMarkerSeries.current);
      setVMarkerSeriesList(vMarkerSeries.current);

      candleChart.timeScale().resetTimeScale();
      volumeChart.timeScale().resetTimeScale();

      // 차트가 마진이 있다가 없다가 하는 이슈가 있음 TBD
      candleChart.timeScale().fitContent();
      volumeChart.timeScale().fitContent();

      // const range = candleChart.timeScale().getVisibleRange();
      // const range_from = range.from;
      // const range_to = range.to;
      // const padding_right = n_agg >= 1440 ? 2 : Math.max(5, range.to / 50); // 일봉은 2개, 그 외는 max(5, 전체 2%)

      // console.log("range", range);
      // console.log("range_from", range_from);
      // console.log("range_to", range_to);
      // console.log("candleChart.timeScale()", candleChart.timeScale());

      // candleChart.timeScale().setVisibleRange({
      //   from: 0,
      //   to: range_to,
      // });

      // change watermark text
      candleChart.applyOptions({
        watermark: {
          ...watermarkCommon,
          fontSize: 14,
          text:
            n_agg < 60
              ? `alphanote.io ${name} ${n_agg}분봉`
              : n_agg < 1440
              ? `alphanote.io ${name} ${n_agg / 60}시간봉`
              : // : `alphanote.io ${name} ${n_agg / 1440}일봉`,
                `alphanote.io ${name} 일봉`,
        },
      });
    }
  }, [
    chartData,
    markersData,
    candleChart,
    volumeChart,
    assetChart,
    cMarkerSeries,
    vMarkerSeries,
    // candleSeries,
    // volumeSeries,
    // cMarkerSeriesList,
    // vMarkerSeriesList,
    mode,
  ]);

  return (
    <>
      <ChartBackgroundWrapper>
        <ChartContainer ref={containerRef} chartWidth={chartWidth} />
        {maShow && chartData?.ma && (
          <MALabelWrapper>
            {Object.keys(chartData.ma).map((key) => {
              return <MALabel color={maStyle[key].color}>{key + "  "}</MALabel>;
            })}
          </MALabelWrapper>
        )}
      </ChartBackgroundWrapper>
    </>
  );
};
