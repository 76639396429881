import React from "react";
import styled from "styled-components";

const BackgroundWrapper = styled.div`
  background-color: #f0f2f5;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  color: #000;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  text-align: left;
`;

const Title = styled.h1`
  font-family: Pretendard;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const Paragraph = styled.p`
  font-family: Pretendard;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
`;

const PrivacyPolicyPage = () => {
  const privacyContent = [
    {
      title: "개인정보의 수집 및 이용 목적",
      content:
        "회사는 다음과 같은 목적으로 개인정보를 수집 및 이용합니다:\n- 서비스 제공 및 계약의 이행\n- 회원 관리\n- 신규 서비스 개발 및 마케팅에의 활용",
    },
    {
      title: "수집하는 개인정보의 항목",
      content:
        "회사는 서비스 제공을 위해 다음과 같은 개인정보를 수집할 수 있습니다:\n- 필수항목: 이름, 이메일 주소, 비밀번호\n- 선택항목: 프로필 사진, 연락처",
    },
    {
      title: "개인정보의 보유 및 이용기간",
      content:
        "회사는 회원탈퇴 시 또는 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.",
    },
    {
      title: "개인정보의 파기절차 및 방법",
      content:
        "회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. 파기절차 및 방법은 다음과 같습니다:\n- 파기절차: 불필요한 개인정보는 개인정보관리책임자의 승인을 받아 파기합니다.\n- 파기방법: 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.",
    },
    {
      title: "개인정보 보호책임자",
      content:
        "회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.\n- 개인정보 보호책임자: [이름]\n- 연락처: [이메일 주소], [전화번호]",
    },
  ];

  return (
    <BackgroundWrapper>
      <ContentWrapper>
        <Title>개인정보 처리방침</Title>

        {privacyContent.map((privacy, index) => (
          <Section key={index}>
            <SectionTitle>{`${index + 1}. ${privacy.title}`}</SectionTitle>
            <Paragraph>{privacy.content}</Paragraph>
          </Section>
        ))}
      </ContentWrapper>
    </BackgroundWrapper>
  );
};

export default PrivacyPolicyPage;
