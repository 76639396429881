import { useLocation, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import styled from "styled-components";
import { unescapeHtml } from "utils/utils";

import { AlphaNoteChartArea } from "components/AlphaNoteChartArea";
import { AlphaNoteUserInfo } from "components/AlphaNoteUserInfo";
import NoticeModal from "components/Modals/NoticeModal";
import { useAlphaNoteQuery } from "hooks/queries/useAlphaNoteQuery";
import { isMobile } from "react-device-detect";
import { useNotice } from "utils/store";

const HomeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-width: 1128px;
  background: #f9fafc;

  .section-bg {
    width: 100%;
    background: #121314;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .fullWidth {
    width: 1128px;
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 80px;
`;

const AlphaNote = () => {
  const navigate = useNavigate();

  const query = useQuery();
  const cuid = query.get("cuid");
  const date = query.get("date");
  const code = query.get("code");

  if (!cuid || !date || !code) {
    navigate("/");
  }

  const note_id = {
    cuid: cuid,
    date: date,
    code: code,
  };

  const { data, isError, isLoading } = useAlphaNoteQuery({ note_id });

  const { isNoticeShow } = useNotice((state) => state);
  const [isNoticeOpen, setNoticeOpen] = useState(false);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error loading data</p>;
  }

  const { name } = data?.info || {};

  document.title = `${unescapeHtml(name)} - ${unescapeHtml(
    data?.trader?.nick
  )}`;

  // console.log("data", data);
  return (
    <HomeWrapper>
      <ContentWrapper>
        <AlphaNoteUserInfo
          userName={data?.trader?.nick}
          trader={data?.trader}
          competition={data?.competition}
        />
        <AlphaNoteChartArea data={data} />
      </ContentWrapper>
    </HomeWrapper>
  );
};

export default AlphaNote;
