import styled from "styled-components";

import { ReactComponent as ArrowLeft } from "assets/images/ico_arrow_left_s.svg";
import { ReactComponent as ArrowRight } from "assets/images/ico_arrow_right_s.svg";

const CalendarButtonWrapper = styled.div`
  align-items: center;
  margin-left: 8px;
  border-collapse: collapse;
  display: flex;

  .left {
    border-radius: 4px 0 0 4px;
  }

  .right {
    border-radius: 0 4px 4px 0;
    border-left: 0;
  }
`;

const CalendarButton = styled.div`
  width: 24px;
  height: 24px;
  background: #ffffff;

  text-align: center;
  cursor: pointer;
  border: 1px solid #e7e9ef;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #f2f4f7;
  }

  &:active {
    background: #e7e9ef;
  }

  ${({ disabled }) =>
    disabled &&
    `
    background: #F9FAFC;
    opacity: 0.5;
    cursor: not-allowed;
`}
`;

export const CalendarButtons = ({
  handlePrevDate = () => {},
  handleNextDate = () => {},
  idx = 1,
  total = 1,
}) => (
  <CalendarButtonWrapper>
    <CalendarButton
      className="left"
      disabled={idx === 0}
      onClick={() => idx !== 0 && handlePrevDate()}
    >
      <ArrowLeft />
    </CalendarButton>
    <CalendarButton
      className="right"
      disabled={idx === total - 1}
      onClick={() => idx !== total - 1 && handleNextDate()}
    >
      <ArrowRight />
    </CalendarButton>
  </CalendarButtonWrapper>
);
