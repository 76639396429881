import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f6f7;
  padding: 20px;
`;

const LoginBox = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 40px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

const Description = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  text-align: center;
`;

const KakaoButton = styled.button`
  background-color: #fee500;
  border: none;
  border-radius: 5px;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  margin-bottom: 20px;

  &:hover {
    background-color: #e0c200;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

const Link = styled.a`
  font-size: 12px;
  color: #666;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const LoginPage = () => {
  const handleKakaoLogin = async () => {
    const REST_API_KEY = process.env.REACT_APP_KAKAO_CLIENT;
    const REDIRECT_URI = process.env.REACT_APP_KAKAO_REDIRECT;
    const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;

    // GTM 이벤트 전송
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "kakao_login_click",
    });

    // 약간의 지연 후 리디렉션
    setTimeout(() => {
      window.location.href = KAKAO_AUTH_URL;
    }, 300);
  };

  const openLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Container>
      <LoginBox>
        <Title>AlphaNote 로그인</Title>
        <Description>
          AlphaNote는 카카오 계정으로 간편하게 가입하고 로그인할 수 있습니다.
          아래 버튼을 클릭하여 시작하세요.
        </Description>
        <KakaoButton onClick={handleKakaoLogin}>
          카카오 아이디로 시작하기
        </KakaoButton>
        <Description>
          계속 진행하면 AlphaNote의 이용약관 및 개인정보 처리방침에 동의하는
          것으로 간주됩니다.
        </Description>
        <LinkContainer>
          <Link onClick={() => openLink("/terms")}>이용약관</Link>
          <Link onClick={() => openLink("/policy")}>개인정보 처리방침</Link>
        </LinkContainer>
      </LoginBox>
    </Container>
  );
};

export default LoginPage;
