import { CircularProgress } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/images/ico_close_m.svg";
import { useDeleteMyNoteHistory } from "hooks/queries/useAlphaTraderDetailQuery";
import useHttpClient from "hooks/useHttpClient";
import { DeleteIcon, LinkOutIcon } from "pages/TraderDetail/AlphaDetailMyNote";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import ReactModal from "react-modal";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { addCommaToInt, formatDateToYMD } from "utils/utils";

const customStyles = {
  overlay: {
    zIndex: 1000,
    backgroundColor: "#00000070",
  },
  content: {
    width: "520px",
    inset: "unset",
    margin: "50vh auto",
    padding: 0,
    transform: "translateY(-50%)",
    position: "relative",
    borderRadius: "8px",
  },
};

const ModalWrapper = styled.div`
  border-radius: 8px;
  font-family: Pretendard;
  text-align: left;
`;
const ModalHeader = styled.div`
  padding: 20px 20px 12px;
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  font-weight: 600;
  line-height: 20.29px;

  svg {
    cursor: pointer;
  }
`;

const ModalBody = styled.div`
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  font-family: Pretendard;

  font-size: 15px;
  font-weight: 400;
  line-height: 17.9px;
  text-align: left;

  .button {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.71px;
    width: 82px;
    border: 1px solid #e7e9ef;
    border-left: none;
    border-radius: 0 8px 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }

  ul {
    color: #7d7e80;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    padding: 0 20px;
    margin-bottom: 0;
  }
`;

const ModalBottom = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 16px 20px 20px 20px;
`;

const ButtonWrapper = styled.div`
  box-sizing: border-box;
  width: 100px;
  height: 40px;
  border-radius: 6px;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.71px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #000000;
  color: #ffffff;

  cursor: pointer;

  ${({ secondary }) =>
    secondary &&
    `
    background: #ffffff;
    color: #000000;
    border: 1px solid #E7E9EF;
`}

  ${({ disabled }) =>
    disabled &&
    `
    background: #E7E7E7;
    color: #ffffff;
    cursor: not-allowed;
`}
`;

const FileListWrapper = styled.div`
  width: 100%;
  max-height: 210px;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: Pretendard;
  overflow-y: auto;

  th,
  td {
    box-sizing: border-box;
    padding: 0 8px;
    text-align: right;
    height: 30px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.32px;

    &:nth-child(1) {
      width: 190px;
      text-align: left;
      display: flex;
      align-items: center;
      gap: 12px;
    }
    &:nth-child(2) {
      width: 90px;
      text-align: left;
    }
    &:nth-child(3) {
      width: 90px;
      text-align: left;
    }
    &:nth-child(4) {
      width: 64px;
      text-align: right;
    }
    &:nth-child(5) {
      width: 46px;
    }
  }

  th {
    background: #f2f4f7;
    color: #7d7e80;
    &:nth-child(1) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:nth-child(5) {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  td {
    height: 40px;
    font-weight: 400;
    color: #323233;
    border-bottom: 1px solid #f2f4f7;

    svg {
      cursor: pointer;
    }
  }
`;
const FileSelectButton = styled.div`
  width: 72px;
  height: 32px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e7e9ef;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;
  color: #4b4c4d;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 21px;
`;
const FileSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: Pretendard;

  .info {
    margin-top: 8px;
    font-size: 15px;
    font-weight: 500;
    line-height: 17.9px;
    text-align: center;
  }
  .infourl {
    margin-top: 3px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    text-align: center;
    color: #4c67ff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    text-decoration: underline;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th,
  td {
    border: 1px solid #e7e9ef;
    padding: 8px;
    text-align: left;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
  }

  th {
    background-color: #f9fafc;
    font-weight: 600;
  }

  tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const CloudIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5073 12.4476C16.3738 12.3249 16.1957 12.25 16 12.25C15.8447 12.25 15.7004 12.2972 15.5807 12.3781C15.5507 12.3984 15.5222 12.4208 15.4955 12.445L9.9955 17.445C9.68901 17.7237 9.66642 18.198 9.94505 18.5045C10.2237 18.811 10.698 18.8336 11.0045 18.555L15.25 14.6954L15.25 30C15.25 30.4142 15.5858 30.75 16 30.75C16.4142 30.75 16.75 30.4142 16.75 30L16.75 14.6954L20.9955 18.555C21.302 18.8336 21.7763 18.811 22.055 18.5045C22.3336 18.198 22.311 17.7237 22.0045 17.445L16.5073 12.4476Z"
      fill="#DCDEE3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.37245 7.53742L9.64572 7.9902L10.2284 6.77089C11.437 4.24194 14.0165 2.5 17 2.5C20.9292 2.5 24.154 5.52258 24.4739 9.36836L24.5626 10.4355L25.6007 10.6982C28.4174 11.411 30.5 13.9641 30.5 17C30.5 20.5899 27.5899 23.5 24 23.5H20H19V25H20H24C28.4183 25 32 21.4183 32 17C32 13.2611 29.435 10.1212 25.9687 9.24405C25.5848 4.62724 21.716 1 17 1C13.4172 1 10.3233 3.09358 8.87503 6.12412C7.28954 5.56031 5.45109 5.91292 4.18203 7.18198C2.84966 8.51435 2.52745 10.4744 3.2154 12.1103C1.28104 13.3558 0 15.5283 0 18C0 21.866 3.13401 25 7 25H13V23.5H7C3.96243 23.5 1.5 21.0376 1.5 18C1.5 16.0595 2.50377 14.3526 4.02747 13.3715L5.08628 12.6897L4.59811 11.5288C4.13831 10.4354 4.35612 9.12922 5.24269 8.24264C6.08713 7.3982 7.31182 7.16025 8.37245 7.53742Z"
      fill="#DCDEE3"
    />
  </svg>
);
const MyNoteUploadModal = ({
  isOpen,
  onCancel,
  title = "",
  setSnackOpen = () => {},
  body = <></>,
  onClickConfirm = () => {},
  refetch = () => {},
}) => {
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [uploadList, setUploadList] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const {
    mutate: deleteMutate,
    data: deleteData,
    error,
  } = useDeleteMyNoteHistory();

  const api_v_url = "https://api.alphanote.io/api/";

  const [file, setFile] = useState(null);
  const httpClient = useHttpClient(api_v_url);

  const mutation = useMutation(
    async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      const response = await httpClient.post(
        "/v1b/my/history_attachment",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        console.log("File uploaded successfully:", data);
      },
      onError: (error) => {
        console.error("Error uploading file:", error);
      },
    }
  );

  const uploadMutation = useMutation(
    async () => {
      const response = await httpClient.post("/v1b/my/history");
      return response.data;
    },
    {
      onSuccess: (data) => {
        refetch();
        onCancel();
        setUploadList([]);
        setIsReady(false);
      },
      onError: (error) => {
        console.error("Error uploadMutation:", error);
      },
    }
  );

  const stateQuery = useQuery("state", () => {
    return httpClient.get("/v1b/my/history_attachment_status");
  });

  const handleClickCancel = () => {
    onCancel();
    setUploadList([]);
    setIsReady(false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);

    setAcceptedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);

    acceptedFiles.forEach((file) => {
      mutation.mutate(file);
      setUploadList((prevList) => [
        ...prevList,
        {
          filename: file.name,
          first_transaction: 0,
          last_transaction: 0,
          n_stock: 0,
          n_trx: 0,
          status: "uploading",
          temp_id: null,
        },
      ]);
    });
  }, []);

  useEffect(() => {
    console.log("useEffect HIHI");
    setUploadList([]);
    setIsReady(false);
  }, []);

  useEffect(() => {
    if (deleteData?.removed_id) {
      setUploadList((prevList) =>
        prevList.filter((file) => file.temp_id !== deleteData.removed_id[0])
      );
    }
  }, [deleteData]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    // console.log("handleSubmit", acceptedFiles, acceptedFiles[0]);
    e.preventDefault();

    uploadMutation.mutate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      stateQuery.refetch();
    }, 3000);

    if (!isOpen) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [stateQuery, isOpen]);

  useEffect(() => {
    if (stateQuery.isSuccess) {
      const newHistory = stateQuery.data?.data?.data?.history;
      setUploadList((prevList) => {
        const updatedList = [...prevList];
        newHistory.forEach((newFile) => {
          const existingFileIndex = updatedList.findIndex(
            (file) => file.filename === newFile.filename
          );

          if (existingFileIndex !== -1) {
            updatedList[existingFileIndex] = newFile;
          } else {
            updatedList.push(newFile);
          }
        });

        return updatedList;
      });
      setIsReady(stateQuery?.data?.data?.data?.all_ready);
    }
  }, [stateQuery.data, stateQuery.isSuccess]);

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={handleClickCancel}
    >
      <ModalWrapper>
        <ModalHeader>
          <div>{title}</div>
          <CloseIcon onClick={handleClickCancel} />
        </ModalHeader>
        <ModalBody>
          <div
            {...getRootProps()}
            style={{
              height: "200px",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "2px dashed #C8C9CC",
              borderRadius: "8px",
              padding: "20px",
              cursor: "pointer",
              backgroundColor: "#F9FAFC",
            }}
          >
            <input {...getInputProps()} />
            <FileSection>
              <CloudIcon />
              <div className="info">파일을 이곳에 드래그 해주세요.</div>
              <div
                className="infourl"
                onClick={() =>
                  window.open(
                    "https://www.notion.so/arpa/a15cd364395f4ef18b1f0d8a7e2f3a89",
                    "_blank"
                  )
                }
              >
                매매일지 다운로드 방법 보러가기
                <LinkOutIcon fill="#4C67FF" />
              </div>
              <FileSelectButton>파일 선택</FileSelectButton>
            </FileSection>
          </div>
          {uploadList?.length > 0 && (
            <FileListWrapper>
              <thead>
                <tr>
                  <th>파일명</th>
                  <th>최초 거래일</th>
                  <th>마지막 거래일</th>
                  <th>체결 건수</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {uploadList?.map((file, index) => (
                  <tr key={index}>
                    <td>
                      {file?.filename}
                      {!file?.temp_id && (
                        <CircularProgress size={24} color="white" />
                      )}
                    </td>
                    {file?.temp_id ? (
                      <>
                        <td>
                          {formatDateToYMD(file?.first_transaction * 1000)}
                        </td>
                        <td>
                          {formatDateToYMD(file?.last_transaction * 1000)}
                        </td>
                        <td>{addCommaToInt(file?.n_trx)}건</td>
                        <td
                          onClick={() => {
                            deleteMutate({ temp_id: file?.temp_id });
                          }}
                        >
                          <DeleteIcon fill="#4B4C4D" />
                        </td>
                      </>
                    ) : (
                      <>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </FileListWrapper>
          )}
          <ul>
            <li>영웅문 ‘0343’ 화면에서 csv 파일로 저장 후 업로드 해주세요.</li>
            <li>중복된 데이터는 자동으로 한 번만 저장됩니다.</li>
            <li>정해진 형식이 아니면 파일이 업로드 되지 않습니다.</li>
            <li>추후 더 편하게 업로드 할 수 있도록 준비하겠습니다.</li>
          </ul>
        </ModalBody>
        <ModalBottom>
          <ButtonWrapper secondary onClick={handleClickCancel}>
            취소
          </ButtonWrapper>
          <ButtonWrapper onClick={handleSubmit} disabled={!isReady}>
            확인
          </ButtonWrapper>
        </ModalBottom>
      </ModalWrapper>
    </ReactModal>
  );
};

export default MyNoteUploadModal;
