import { useEffect, useState } from "react";
import styled from "styled-components";

import { useAllInOneQuery } from "hooks/queries/useCommonQuery";
import {
  useCompetitions,
  useCurrentCompetition,
  useSelectedCompetition,
} from "utils/store";
import RangeSelectModal from "./Modals/RangeSelectModal";

const SelectWrapper = styled.div`
  box-sizing: border-box;
  height: 40px;
  padding: 0 20px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${({ is_dark }) =>
      is_dark === "dark" ? "rgba(255, 255, 255, 0.3)" : "rgba(0, 0, 0, 0.3)"};

  cursor: pointer;
  border-style: dotted;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;

  color: ${({ is_dark }) => (is_dark === "dark" ? "#ffffff" : "#191a1a")};

  svg {
    margin-left: 8px;
  }

  &:hover {
    border-style: solid;
  }
`;

const CompetitionArrow = ({ isDarkMode }) => (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M1 0.75L5.69565 5.25L10.5 0.75"
      stroke={isDarkMode ? "#ffffff" : "#000000"}
      strokeWidth="1.5"
      // stroke-linecap="round"
    />
  </svg>
);

const AlphaRangeSelect = ({ isDarkMode = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { competitions, setCompetitions } = useCompetitions((state) => state);
  const { setCurrentCompetition } = useCurrentCompetition((state) => state);
  const { selectedCompetition, setSelectedCompetition } =
    useSelectedCompetition((state) => state);

  const { data: competitionData, isLoading } = useAllInOneQuery();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const selectedCompetitionSubmit = (competition) => {
    setSelectedCompetition(competition);
  };

  useEffect(() => {
    if (competitionData) {
      setCompetitions(competitionData.competitions);
      setCurrentCompetition(competitionData.current_competition);
    }
    if (competitionData && selectedCompetition === null) {
      setSelectedCompetition(competitionData.current_competition);
    }
  }, [competitionData]);

  return (
    <>
      <SelectWrapper
        is_dark={isDarkMode ? "dark" : "default"}
        onClick={() => openModal()}
      >
        {isLoading || selectedCompetition === null ? (
          "Loading..."
        ) : (
          <>
            {selectedCompetition?.broker + "증권 " + selectedCompetition?.name}
            <CompetitionArrow isDarkMode={isDarkMode} />
          </>
        )}
      </SelectWrapper>
      <RangeSelectModal
        isOpen={isOpen}
        onSubmit={selectedCompetitionSubmit}
        onCancel={closeModal}
        competitionList={competitions}
        selectedCompetition={selectedCompetition}
      />
    </>
  );
};

export default AlphaRangeSelect;
