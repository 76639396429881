import useHttpClient, { api_v_url } from "hooks/useHttpClient";
import { useQuery } from "react-query";

function useFavTraderQuery({ cid = "" }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["competition-fav_trader", { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/competition/fav_trader?cid=${cid}`
      );

      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useFavNoteQuery({ cid = "" }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["competition-fav_note", { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/competition/fav_note?cid=${cid}`
      );

      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useLeagueTopQuery({ cid = "" }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["competition-league_top", { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/competition/league_top?cid=${cid}`
      );

      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useTopStyleTraderQuery({ cid = "" }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["competition-top_style_trader", { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/competition/top_style_trader?cid=${cid}`
      );
      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useHotStockQuery({ cid = "" }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["competition-hot_stock", { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/competition/hot_stock?cid=${cid}`
      );

      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useTopNoteQuery({ cid = "" }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["competition-top_note", { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/competition/top_note?cid=${cid}`
      );

      return queryResult?.data?.notes;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useTopNoteChartQuery({ cid = "", sequence = 0 }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["competition-top_note_chart", { cid, sequence }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/competition/top_note_chart?cid=${cid}&sequence=${sequence}`
      );

      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useRankerPerformanceQuery({ cid = "" }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["competition-ranker_performance", { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/competition/ranker_performance?cid=${cid}`
      );

      return queryResult?.data?.chart;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

export {
  useFavNoteQuery,
  useFavTraderQuery,
  useHotStockQuery,
  useLeagueTopQuery,
  useRankerPerformanceQuery,
  useTopNoteChartQuery,
  useTopNoteQuery,
  useTopStyleTraderQuery,
};
