import { ReactComponent as InfoSmallIcon } from "assets/images/ico_info_s.svg";
import { SvgStkImg } from "components/StockIcon";
import ToggleComponet from "components/ToggleComponet";
import { WinningGraph } from "components/TraderDetail/WinningGraph";
import { useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { TRADE_STYLE_TYPE } from "utils/consts";
import {
  addCommaToInt,
  convertToNearWon,
  convertToPercentage,
  convertToPnlRatio,
  convertToWon,
  formatSec,
  unescapeHtml,
} from "utils/utils";

const Section = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  
  grid-template-rows: 160px 396px 308px 450px; 361px; 361px;

  gap: 24px;

  > div:nth-child(1) {
    grid-column: span 1;
    grid-row: span 1;
  }

  > div:nth-child(2) {
    grid-column: span 1;
    grid-row: span 1;
  }

  > div:nth-child(3) {
    grid-column: span 1;
    grid-row: span 2;
  }

  > div:nth-child(4) {
    grid-column: span 2;
    grid-row: span 1;
  }

  > div:nth-child(5) {
    grid-column: span 2;
    grid-row: span 1;
  }

  > div:nth-child(6) {
    grid-column: span 1;
    grid-row: span 1;
  }

  > div:nth-child(7) {
    grid-column: span 1;
    grid-row: span 2;
  }

  > div:nth-child(8) {
    grid-column: span 2;
    grid-row: span 2;
  }

  > div:nth-child(9) {
    grid-column: span 3;
    grid-row: span 1;
  }

`;

const ProfitCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  height: 120px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  .title {
    color: #141414;
  }

  .profit {
    font-weight: 600;
    color: #000000;
    font-size: 28px;
    line-height: 33.41px;
    padding: 10px 0 22px;
  }

  .info {
    color: #afb0b2;
    display: flex;
    justify-content: space-between;
    margin-top: 4px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

const ValueCardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;
`;
const ValueGraphWrapper = styled.div`
  padding: 0 24px;
`;

const ValueTogglerWrapper = styled.div`
  padding: 24px 20px 14px;
  width: fit-content;
`;

const GraphTogglerWrapper = styled.div`
  padding: 14px 0;
  width: fit-content;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;
  text-align: left;

  border-radius: 4px;
`;

const TableRow = styled.tr`
  border: 1px solid #e7e9ef;
  height: 30px;
`;

const TableCell = styled.td`
  border: 1px solid #e7e9ef;
  position: relative;

  &:first-child {
    width: 35px;
    padding-right: 12px;
    text-align: right;
  }
`;

const TableHeadCell = styled.td`
  background: #f2f4f7;
  padding-right: 12px;
  text-align: right;
  &:first-child {
    width: 48px;
    text-align: center;
    padding-right: 0;
  }
`;

const TableLable = styled.div`
  z-index: 2;
  position: absolute;

  right: 12px;
  top: 0;
  display: flex;
  height: 100%;

  justify-content: end;
  align-items: center;

  color: #4b4c4d;
`;

const BarItem = styled.div`
  z-index: 1;
  position: absolute;
  width: ${(props) => props.width}%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
  background: linear-gradient(to right, #ffffff, #ffbfcf);
  transition: width 0.5s ease-in-out;
`;

const ValueStackGraphWrapper = styled.div`
  width: calc(100% - 48px);
  height: 100%;
  align-self: center;
`;

function generateTimeData() {
  const timeData = {
    buys: [],
    sells: [],
  };

  for (let i = 8; i <= 17; i++) {
    timeData.buys.push({
      name: i,
      value: Math.floor(Math.random() * 1000000000),
    });
    timeData.sells.push({
      name: i,
      value: Math.floor(Math.random() * 1000000000),
    });
  }
  return timeData;
}

function generateStyleData() {
  const getRandData = () => {
    const buy = Math.floor(Math.random() * 1000000000);
    const sell = Math.floor(Math.random() * 1000000000);
    const total = buy + sell;
    return { buy, sell, total };
  };

  const styleData = [
    {
      name: "scallping",
      ...getRandData(),
    },
    {
      name: "day",
      ...getRandData(),
    },
    {
      name: "swing",
      ...getRandData(),
    },
  ];
  return styleData;
}

function generateProfitTimeData() {
  const percentData = [];
  for (let i = 11; i < 22; i++) {
    percentData.push({
      date: "08/" + i,
      value: Math.floor(Math.random() * 100),
    });
  }

  const wonData = [];
  for (let i = 11; i < 22; i++) {
    wonData.push({
      date: "08/" + i,
      value: Math.floor(Math.random() * 100000),
    });
  }
  return { percentData, wonData };
}

const ProfitCard = ({
  title,
  profit,
  tag,
  label1,
  label1Data,
  label2,
  label2Data,
}) => {
  return (
    <ProfitCardWrapper>
      <div className="title">{title}</div>
      <div className="profit">{profit}</div>
      <div>
        <div className="info">
          <span>{label1}</span>
          <span>{label1Data}</span>
        </div>
        <div className="info">
          <span>{label2}</span>
          <span>{label2Data}</span>
        </div>
      </div>
    </ProfitCardWrapper>
  );
};

const calcWidth = (value, max) => {
  return (value / max) * 100;
};

function setValue(number) {
  return number === 0 ? "-" : addCommaToInt(number);
}
const ValueCard = ({
  title,
  profit,
  tag,
  label1,
  label1Data,
  label2,
  label2Data,
  timeData = null,
  styleData,
}) => {
  const [currentType, setCurrentType] = useState("time");

  const buysMax = timeData
    ? Math.max(...timeData?.map((item) => item?.amt_buy))
    : 0;
  const sellsMax = timeData
    ? Math.max(...timeData?.map((item) => item?.amt_sell))
    : 0;

  const styleDataArray = styleData
    ? Object.entries(styleData).map(([name, data]) => ({
        name: TRADE_STYLE_TYPE[name],
        ...data,
      }))
    : [];

  return (
    <ValueCardWrapper>
      <ProfitCardWrapper
        style={{
          borderTop: 0,
          borderLeft: 0,
          borderRight: 0,
          borderBottom: "1px dashed #EBEDF5",
          borderRadius: 0,
        }}
      >
        <div className="title">{title}</div>
        <div className="profit">{profit}</div>
        <div>
          <div className="info">
            <span>{label1}</span>
            <span>{label1Data}</span>
          </div>
          <div className="info">
            <span>{label2}</span>
            <span>{label2Data}</span>
          </div>
        </div>
      </ProfitCardWrapper>
      <ValueTogglerWrapper>
        <ToggleComponet
          onClick={(type) => setCurrentType(type)}
          labels={[
            { name: "시간별", type: "time" },
            { name: "스타일별", type: "style" },
          ]}
        />
      </ValueTogglerWrapper>

      {currentType === "time" ? (
        <ValueGraphWrapper>
          <Table>
            <thead>
              <TableRow>
                <TableHeadCell>시간</TableHeadCell>
                <TableHeadCell>매수금액</TableHeadCell>
                <TableHeadCell>매도금액</TableHeadCell>
              </TableRow>
            </thead>
            <tbody>
              {timeData?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.time}</TableCell>
                  <TableCell>
                    <BarItem width={calcWidth(item.amt_buy, buysMax)} />
                    <TableLable>
                      {setValue(convertToNearWon(item.amt_buy))}
                    </TableLable>
                  </TableCell>
                  <TableCell>
                    <BarItem width={calcWidth(item.amt_sell, sellsMax)} />
                    <TableLable>
                      {setValue(convertToNearWon(item.amt_sell))}
                    </TableLable>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </ValueGraphWrapper>
      ) : (
        <ValueStackGraphWrapper>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={styleDataArray}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <Tooltip cursor={{ fill: "transparent" }} />
              <Bar dataKey="amt_buy" stackId="a" fill="#FFD5E0" barSize={16} />
              <Bar dataKey="amt_sell" stackId="a" fill="#FF3D6C" barSize={16} />
            </BarChart>
          </ResponsiveContainer>
        </ValueStackGraphWrapper>
      )}
    </ValueCardWrapper>
  );
};

const ProfitTimeCard = ({ title, data }) => {
  const [currentType, setCurrentType] = useState("percent");

  return (
    <ProfitTimeCardWrapper>
      <div className="title">{title}</div>

      <GraphTogglerWrapper>
        <ToggleComponet
          onClick={(type) => setCurrentType(type)}
          labels={[
            { name: "수익률", type: "percent" },
            { name: "수익금", type: "won" },
          ]}
        />
      </GraphTogglerWrapper>
      <ValueAreaGraphWrapper>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={(tickItem) => {
                const date = new Date(tickItem * 1000);
                const month = (date.getMonth() + 1).toString().padStart(2, "0"); // months are 0-based in JavaScript
                const day = date.getDate().toString().padStart(2, "0");
                return `${month}-${day}`;
              }}
            />
            <YAxis />
            <Tooltip cursor={{ fill: "transparent" }} />
            <Area
              type="monotone"
              dataKey={currentType === "percent" ? "ret" : "pnl"}
              stroke="#FF3D6C"
              fill="#FFD5E0"
            />
          </AreaChart>
        </ResponsiveContainer>
      </ValueAreaGraphWrapper>
    </ProfitTimeCardWrapper>
  );
};

const ValueAreaGraphWrapper = styled.div`
  width: calc(100% - 48px);
  height: calc(100% - 71px);
`;

const ProfitTimeCardWrapper = styled.div`
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  height: 356px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  .title {
    color: #141414;
  }
`;

const WinningRateCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0;

  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;
`;

const WinningRateCardItem = styled.div`
  padding: 20px;

  display: flex;
  flex-direction: column;

  border: ${(props) =>
    props.isGrid ? "1px dashed #ebedf5" : "1px solid #EBEBEB"};

  ${(props) =>
    props.isGrid
      ? `
  &:nth-child(-n + 3) {
    border-top: none;
  }

  &:nth-child(n + 4) {
    border-bottom: none;
  }

  &:nth-child(3n + 1) {
    border-left: none;
  }

  &:nth-child(3n) {
    border-right: none;
  }
  `
      : `border-radius: 6px;`};

  .value {
    margin-top: 5px;
    font-family: Pretendard;
    font-size: 25px;
    font-weight: 600;
    line-height: 29.83px;
    text-align: left;
  }

  .infoWrapper {
    margin-top: 24px;
  }

  .info {
    color: #afb0b2;
    display: flex;
    justify-content: space-between;
    margin-top: 4px;

    &:first-child {
      margin-top: 0;
    }
  }

  .title {
    display: flex;
    align-items: center;
  }

  svg {
    margin-left: 3px;
  }
`;

const WinningGraphWrapper = styled.div`
  width: 100%;
  margin-top: auto;
`;

export const WinningRateCard = ({ item, isGrid = false }) => {
  return (
    <WinningRateCardItem isGrid={isGrid} key={"winning-card-" + item.title}>
      <div className="title">
        {item.title}
        {item?.hasInfo && (
          <InfoSmallIcon
            data-tooltip-id="detail-tooltip"
            data-tooltip-content={item?.infoText}
          />
        )}
      </div>
      <div className="value">{item.value}</div>
      {item.info.type === "graph" && (
        <WinningGraphWrapper>
          <WinningGraph win={item.info.win} lose={item.info.lose} />
        </WinningGraphWrapper>
      )}
      {item.info.type === "graph_custom" && <div></div>}
      {item.info.type === "text" && (
        <div className="infoWrapper">
          {item.info.value.map((infoItem, index) => (
            <div className="info" key={index}>
              <span>{infoItem.text}</span>
              <span>{infoItem.value}</span>
            </div>
          ))}
        </div>
      )}
    </WinningRateCardItem>
  );
};

const WinningRateCardGrid = ({ data }) => {
  return (
    <WinningRateCardWrapper>
      {data.map((item, index) => (
        <WinningRateCard item={item} isGrid={true} key={index} />
      ))}
    </WinningRateCardWrapper>
  );
};

const StockCardWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  padding: 20px;

  .title {
    color: #141414;
    font-weight: 600;

    text-align: left;
  }

  .stockWrapper {
    margin-top: 20px;

    .stockItem {
      height: 41px;
      display: flex;
      justify-content: space-between;
    }

    .stockName {
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.71px;
      text-align: left;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .stockPercent {
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.71px;
      text-align: right;
      color: #ff2727;
    }
    .stockWon {
      color: #323233;
      font-family: Pretendard;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.32px;
      text-align: right;
    }
  }
`;
const StockCard = ({ title, data }) => {
  return (
    <StockCardWrapper>
      <div className="title">{title}</div>
      <div className="stockWrapper">
        {data.map((item, index) => (
          <div className="stockItem" key={"stock-" + index}>
            <div className="stockName">
              <SvgStkImg code={item.code} name={item.title} size={30} />
              &nbsp;
              {item.title}
            </div>
            <div className="profitWrapper">
              <div className="stockPercent">{item.percent}</div>
              <div className="stockWon">{item.won}</div>
            </div>
          </div>
        ))}
      </div>
    </StockCardWrapper>
  );
};

const TradingStyleCardWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  padding: 20px;

  .title {
    color: #141414;
  }

  .profit {
    font-weight: 600;
    color: #000000;
    font-size: 28px;
    line-height: 33.41px;
    padding: 10px 0 22px;
  }

  .toggle {
    width: fit-content;
  }
`;

const PieGraphWrapper = styled.div`
  height: 200px;
  display: flex;
  height: 200px;
  margin-top: 17px;
  width: 100%;
`;

const piedata = [
  { name: "스캘핑", value: 321 },
  { name: "데이", value: 224 },
  { name: "스윙", value: 171 },
];
const COLORS = ["#FF3D6C", "#73D6B7", "#8268EB"];

const PieDataWrapper = styled.div`
  margin-top: 31px;
`;

const PieData = styled.div`
  display: flex;

  .block {
    width: 8px;
    height: 8px;
    background: ${(props) => props.color};
    align-self: center;
    margin-right: 5px;
  }

  .name {
  }
  .time {
    margin-left: auto;
    color: #969799;

    font-size: 15px;
    font-weight: 400;
    line-height: 17.9px;
    text-align: right;
  }
  .percent {
    font-size: 15px;
    font-weight: 500;
    line-height: 17.9px;
    text-align: right;
    margin-left: 13px;
  }
`;

const TradingStyleCard = ({ title, value, data }) => {
  const [currentType, setCurrentType] = useState("trade");
  const [currentIndex, setCurrentIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setCurrentIndex(index);
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius * 1.04} // Increase the size by 4%
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          opacity={1} // Set the opacity to 100%
        />
      </g>
    );
  };

  const trxtotal = data.trx.swing + data.trx.day + data.trx.scalping;

  const trxValue = () => {
    if (data.trx.swing > data.trx.day && data.trx.swing > data.trx.scalping) {
      return "스윙 " + convertToPercentage((data.trx.swing / trxtotal) * 100);
    } else if (data.trx.day > data.trx.scalping) {
      return "데이 " + convertToPercentage((data.trx.day / trxtotal) * 100);
    } else {
      return (
        "스캘핑 " + convertToPercentage((data.trx.scalping / trxtotal) * 100)
      );
    }
  };

  const pnltotal = data.pnl.swing + data.pnl.day + data.pnl.scalping;

  const pnlValue = () => {
    if (data.pnl.swing > data.pnl.day && data.pnl.swing > data.pnl.scalping) {
      return "스윙 " + convertToPercentage((data.pnl.swing / pnltotal) * 100);
    } else if (data.pnl.day > data.pnl.scalping) {
      return "데이 " + convertToPercentage((data.pnl.day / pnltotal) * 100);
    } else {
      return (
        "스캘핑 " + convertToPercentage((data.pnl.scalping / pnltotal) * 100)
      );
    }
  };

  const trxData = [
    { name: "스캘핑", value: data.trx.scalping },
    { name: "데이", value: data.trx.day },
    { name: "스윙", value: data.trx.swing },
  ];

  const pnlData = [
    { name: "스캘핑", value: data.pnl.scalping },
    { name: "데이", value: data.pnl.day },
    { name: "스윙", value: data.pnl.swing },
  ];

  return (
    <TradingStyleCardWrapper key={"tading-style-" + title}>
      <div className="title">{title}</div>
      <div className="profit">
        {currentType === "trade" ? trxValue() : pnlValue()}
      </div>
      <div className="toggle">
        <ToggleComponet
          onClick={(type) => setCurrentType(type)}
          labels={[
            { name: "매매", type: "trade" },
            { name: "수익금", type: "profit" },
          ]}
        />
      </div>
      <PieGraphWrapper>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={currentType === "trade" ? trxData : pnlData}
              cx={"50%"}
              cy={"50%"}
              innerRadius={72}
              outerRadius={91}
              fill="#8884d8"
              paddingAngle={1}
              dataKey="value"
              activeIndex={currentIndex}
              activeShape={renderActiveShape}
              onMouseEnter={onPieEnter}
            >
              {(currentType === "trade" ? trxData : pnlData).map(
                (entry, index) => (
                  <Cell
                    key={`pie-cell-${currentType}-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                )
              )}
            </Pie>
            <Tooltip cursor={{ fill: "transparent" }} />
          </PieChart>
        </ResponsiveContainer>
      </PieGraphWrapper>
      <PieDataWrapper>
        {(currentType === "trade" ? trxData : pnlData).map((item, index) => (
          <PieData color={COLORS[index % COLORS.length]} key={index}>
            <div className="block" />
            <div className="name">{item.name}</div>
            <div className="time">
              {currentType === "trade"
                ? item.value + "회"
                : convertToWon(item.value)}
            </div>
            <div className="percent">
              {convertToPercentage(
                (item.value / (currentType === "trade" ? trxtotal : pnltotal)) *
                  100
              )}
            </div>
          </PieData>
        ))}
      </PieDataWrapper>
    </TradingStyleCardWrapper>
  );
};

const StyleCardWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  display: flex;
  flex-direction: row;
`;

const StyleBarCard = styled.div`
  width: 100%;
  padding: 20px;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  .chart {
    height: 312px;
    margin-top: 24px;
  }

  .title {
    color: #141414;
  }

  .value {
    font-weight: 600;
    color: #000000;
    font-size: 28px;
    line-height: 33.41px;
    padding: 10px 0 18px;
  }

  .toggle {
    width: fit-content;
  }

  &:first-child {
    border-right: 1px dashed #ebedf5;
  }
`;

const styleData = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
  },
];

export const StyleResultCard = ({ scalpingData, dayData, swingData }) => {
  const [currentType, setCurrentType] = useState("winning");

  const winnngData = [
    {
      name: "스켈핑",
      win_rate: scalpingData.win_rate,
      match:
        scalpingData.ret * scalpingData.win_rate +
        "승 " +
        scalpingData.ret * (1 - scalpingData.win_rate) +
        "패",
    },
    {
      name: "데이",
      win_rate: dayData.win_rate,
      match:
        dayData.ret * dayData.win_rate +
        "승 " +
        dayData.ret * (1 - dayData.win_rate) +
        "패",
    },
    {
      name: "스윙",
      win_rate: swingData.win_rate,
      match:
        swingData.ret * swingData.win_rate +
        "승 " +
        swingData.ret * (1 - swingData.win_rate) +
        "패",
    },
  ];

  const max_winning = winnngData.reduce((prev, current) =>
    prev.win_rate > current.win_rate ? prev : current
  );

  const retData = [
    {
      name: "스켈핑",
      ret: scalpingData.ret,
      pnl: scalpingData.pnl,
    },
    {
      name: "데이",
      ret: dayData.ret,
      pnl: dayData.pnl,
    },
    {
      name: "스윙",
      ret: swingData.ret,
      pnl: swingData.pnl,
    },
  ];

  const max_ret = retData.reduce((prev, current) =>
    prev.ret > current.ret ? prev : current
  );

  const StyleValue = {
    winning: max_winning.name + " " + convertToPercentage(max_winning.win_rate),
    ret: max_ret.name + " " + convertToPercentage(max_ret.ret),
  };

  const StyleData = {
    winning: winnngData,
    ret: retData,
  };

  const StyleKey = {
    winning: "win_rate",
    ret: "ret",
  };

  return (
    <StyleBarCard>
      <div className="title">{"스타일별 성과"}</div>
      <div className="value">{StyleValue[currentType]}</div>
      <div className="toggle">
        <ToggleComponet
          onClick={(type) => setCurrentType(type)}
          labels={[
            { name: "승률", type: "winning" },
            { name: "수익률", type: "ret" },
          ]}
        />
      </div>
      <div className="chart">
        <ResponsiveContainer>
          <BarChart data={StyleData[currentType]}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip cursor={{ fill: "transparent" }} />
            <Bar dataKey={StyleKey[currentType]} fill="#8884d8" barSize={16}>
              {StyleData[currentType].map((entry, index) => (
                <Cell
                  key={`cell-${currentType + " " + index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </StyleBarCard>
  );
};

const StylePnlCard = ({ scalpingData, dayData, swingData }) => {
  const [currentType, setCurrentType] = useState("pnl_ratio");

  const pnlRatioData = [
    {
      name: "스켈핑",
      pnl_ratio: scalpingData.pnl_ratio,
      avg_ret_win: scalpingData.avg_ret_win,
      avg_ret_lose: scalpingData.avg_ret_lose,
    },
    {
      name: "데이",
      pnl_ratio: dayData.pnl_ratio,
      avg_ret_win: dayData.avg_ret_win,
      avg_ret_lose: dayData.avg_ret_lose,
    },
    {
      name: "스윙",
      pnl_ratio: swingData.pnl_ratio,
      avg_ret_win: swingData.avg_ret_win,
      avg_ret_lose: swingData.avg_ret_lose,
    },
  ];

  const max_pnlRatio = pnlRatioData.reduce((prev, current) =>
    prev.pnl_ratio > current.pnl_ratio ? prev : current
  );

  const pnlData = [
    {
      name: "스켈핑",
      pnl: scalpingData.pnl,
      pnl_win: scalpingData.pnl_win,
      pnl_lose: scalpingData.pnl_lose,
    },
    {
      name: "데이",
      pnl: dayData.pnl,
      pnl_win: dayData.pnl_win,
      pnl_lose: dayData.pnl_lose,
    },
    {
      name: "스윙",
      pnl: swingData.pnl,
      pnl_win: swingData.pnl_win,
      pnl_lose: swingData.pnl_lose,
    },
  ];

  const max_pnl = pnlData.reduce((prev, current) =>
    prev.pnl > current.pnl ? prev : current
  );

  const StyleValue = {
    pnl_ratio: max_pnlRatio.name + " " + convertToPnlRatio(max_pnl.pnl_ratio),
    pnl: max_pnl.name + " " + convertToWon(max_pnl.pnl),
  };

  const StyleData = {
    pnl_ratio: pnlRatioData,
    pnl: pnlData,
  };

  const StyleKey = {
    pnl_ratio: ["avg_ret_win", "avg_ret_lose"],
    pnl: ["pnl_win", "pnl_lose"],
  };

  return (
    <StyleBarCard>
      <div className="title">{"스타일별 손익"}</div>
      <div className="value">{StyleValue[currentType]}</div>
      <div className="toggle">
        <ToggleComponet
          onClick={(type) => setCurrentType(type)}
          labels={[
            { name: "손익비", type: "pnl_ratio" },
            { name: "손익금액", type: "pnl" },
          ]}
        />
      </div>
      <div className="chart">
        <ResponsiveContainer>
          <BarChart data={StyleData[currentType]}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip cursor={{ fill: "transparent" }} />
            <Bar
              dataKey={StyleKey[currentType][0]}
              fill="#FF3D6C"
              barSize={16}
            />
            <Bar
              dataKey={StyleKey[currentType][1]}
              fill="#FFD5E0"
              barSize={16}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </StyleBarCard>
  );
};

const StyleCard = ({ data }) => {
  const { scalping: scalpingData, day: dayData, swing: swingData } = data;

  return (
    <StyleCardWrapper>
      <StyleResultCard
        scalpingData={scalpingData}
        dayData={dayData}
        swingData={swingData}
      />

      <StylePnlCard
        scalpingData={scalpingData}
        dayData={dayData}
        swingData={swingData}
      />
    </StyleCardWrapper>
  );
};

const ScatterCardWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;

  padding: 20px 20px 11px;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  .title {
    color: #141414;
  }

  .chart {
    height: 334px;
    width: 100%;
    margin-top: 17px;
  }
`;

const ScatterCard = (data) => {
  const renderScatterShape = (props) => {
    const { cx, cy, payload, fill } = props;

    // domain={[60 * 5, 60 * 30, 3600 * 3, 3600 * 24]}

    let rangeFill = fill;
    if (payload.hold_time < 60 * 5) {
      rangeFill = "#8268EB";
    } else if (payload.hold_time < 60 * 30) {
      rangeFill = "#4A9B9A";
    } else if (payload.hold_time < 3600 * 3) {
      rangeFill = "#DA6095";
    } else if (payload.hold_time < 3600 * 24) {
      rangeFill = "#4393E2";
    } else {
      rangeFill = "#FFCC38";
    }

    return (
      <circle
        cx={cx}
        cy={cy}
        r={7}
        fill={rangeFill}
        stroke="#000"
        strokeWidth={1.5}
        fillOpacity={0.3}
      />
    );
  };

  return (
    <ScatterCardWrapper>
      <div className="title">보유시간별 성과</div>
      <div className="chart">
        <ResponsiveContainer width="100%">
          <ScatterChart
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid />
            <XAxis
              type="number"
              dataKey="hold_time"
              name="hold_time"
              domain={["dataMin", 3600 * 24]}
              scale="log"
              ticks={[60 * 5, 60 * 30, 3600 * 3, 3600 * 24]}
              tickFormatter={(tickItem) => `${tickItem / 60}분`}
            />
            <YAxis type="number" dataKey="ret" name="ret" unit="%" />
            <Tooltip
              cursor={{ strokeDasharray: "3 3" }}
              content={({ payload }) => (
                <div
                  style={{
                    backgroundColor: "#ffffff",
                    border: "1px solid #ebebeb",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <p>{`종목: ${payload[0]?.payload?.stock_name} (${payload[0]?.payload?.stock_code})`}</p>
                  <p>{`보유시간: ${formatSec(
                    payload[0]?.payload?.hold_time
                  )}`}</p>
                  <p>{`수익률: ${convertToPercentage(
                    payload[0]?.payload?.ret
                  )}`}</p>
                  {/* 추가하려는 정보를 여기에 작성하세요 */}
                </div>
              )}
            />
            <ReferenceLine y={0} stroke="#000" />
            <Scatter
              name="A school"
              data={data.data}
              fill="#8884d8"
              shape={renderScatterShape}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </ScatterCardWrapper>
  );
};

const DetailSummarySection = ({ summaryPerformanceData }) => {
  const {
    card_ret,
    card_pnl,
    card_amt,
    card_flow,
    card_detail,
    card_stocks,
    card_style,
    card_chart,
  } = summaryPerformanceData || {};

  return (
    summaryPerformanceData && (
      <Section>
        <ProfitCard
          title={"수익률"}
          profit={card_ret?.ret ? convertToPercentage(card_ret?.ret) : "-"}
          tag={{ total: card_ret?.n_participant, rank: card_ret?.n_rank }}
          label1={"투자 기간"}
          label1Data={card_ret?.n_day + "일"}
          label2={"투자 날짜"}
          // label2Data={card_ret?.daterange}
          label2Data={
            card_ret?.daterange2.start === card_ret?.daterange2.end
              ? new Date(card_ret?.daterange2.start * 1000)
                  .toISOString()
                  .slice(5, 10)
              : `${new Date(card_ret?.daterange2.start * 1000)
                  .toISOString()
                  .slice(5, 10)} - ${new Date(
                  card_ret?.daterange2.end * 1000 - 24 * 60 * 60 * 1000
                )
                  .toISOString()
                  .slice(5, 10)}`
          }
        />
        <ProfitCard
          title={"투자 손익"}
          profit={"최소 " + convertToNearWon(card_pnl?.pnl)}
          tag={{ total: 123, rank: 4 }}
          label1={"최대 진입금액"}
          label1Data={convertToNearWon(
            Math.max(card_pnl?.max_aum, card_pnl?.seed)
          )}
          label2={"최소 진입금액"}
          label2Data={convertToNearWon(card_pnl?.min_aum)}
        />

        <ValueCard
          title={"거래대금"}
          profit={convertToNearWon(card_amt?.amt)}
          tag={{ total: 123, rank: 4 }}
          label1={"총 매수"}
          label1Data={convertToNearWon(card_amt?.amt_buy)}
          label2={"총 매도"}
          label2Data={convertToNearWon(card_amt?.amt_sell)}
          timeData={card_amt?.chart_time}
          styleData={card_amt?.chart_style}
        />

        <ProfitTimeCard title={"수익 흐름"} data={card_flow} />
        <WinningRateCardGrid
          data={[
            {
              title: "거래 승률",
              hasInfo: true,
              infoText: "승률 = 익절 거래 수 / 전체 거래 수",
              value: convertToPercentage(card_detail?.win_rate),
              info: {
                win: card_detail?.n_win,
                lose: card_detail?.n_lose,
                type: "graph",
              },
            },
            {
              title: "종목 승률",
              hasInfo: true,
              infoText: "종목승률 = 익절한 종목 수 / 전체 거래한 종목 수",
              value: convertToPercentage(card_detail?.stock_win_rate),
              info: {
                win: card_detail?.n_stock_win,
                lose: card_detail?.n_stock_lose,
                type: "graph",
              },
            },
            {
              title: "투자 성향",
              hasInfo: true,
              infoText: "",
              value: card_detail?.tendency,
              info: { valueText: card_detail?.tendency, type: "graph_custom" },
            },

            {
              title: "일 평균 거래 종목",
              value: `${String(card_detail?.avg_n_stock).slice(0, 5)}종목`,
              info: {
                type: "text",
                value: [
                  { text: "총 종목 수", value: card_detail?.n_stock + "종목" },
                  {
                    text: "하루 최대 거래 종목",
                    value: card_detail?.max_n_stock_day + "종목",
                  },
                ],
              },
            },

            {
              title: "일 평균 체결 건수",
              value: `${String(card_detail?.avg_n_trx).slice(0, 5)}건`,
              info: {
                type: "text",
                value: [
                  {
                    text: "총 체결 건수",
                    value: card_detail?.n_trx + "건",
                  },
                  {
                    text: "하루 최대 체결 건수",
                    value: card_detail?.max_n_trx + "건",
                  },
                ],
              },
            },
            {
              title: "손익비",
              hasInfo: true,
              infoText: "손익비 = 평균 익절률 / 평균 손절률",
              value: convertToPnlRatio(card_detail?.pnl_ratio),
              info: {
                type: "text",
                value: [
                  {
                    text: "평균 익절률",
                    value: convertToPercentage(card_detail?.avg_ret_win),
                  },
                  {
                    text: "평균 손절률",
                    value: convertToPercentage(card_detail?.avg_ret_lose),
                  },
                ],
              },
            },
          ]}
        />

        <StockCard
          title="대표 종목"
          data={
            card_stocks?.map((stock) => ({
              title: unescapeHtml(stock?.name),
              code: stock?.code,
              percent: convertToPercentage(stock?.ret),
              won: convertToNearWon(stock?.pnl),
            })) || []
          }
        />
        <TradingStyleCard
          title="트레이딩 스타일"
          value={""}
          data={{
            trx: {
              scalping: card_style.scalping.n_tr,
              day: card_style.day.n_tr,
              swing: card_style.swing.n_tr,
            },
            pnl: {
              scalping: card_style.scalping.pnl,
              day: card_style.day.pnl,
              swing: card_style.swing.pnl,
            },
          }}
        />
        <StyleCard data={card_style} />
        <ScatterCard data={card_chart} />
      </Section>
    )
  );
};
export default DetailSummarySection;
