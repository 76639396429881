// Import necessary dependencies
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

// Styled components
const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  z-index: 999;
`;

const DropdownButton = styled.button`
  width: 114px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #e7e9ef;
  background: #ffffff;
  color: #4b4c4d;

  font-family: Pretendard;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.9px;
  text-align: left;

  padding: 10px 12px;

  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    margin: 2px 0 0 0;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  background-color: #fff;
  box-shadow: 0px 4px 14px 0px #767d9217;

  border-radius: 6px;
  border: 1px solid #e7e9ef;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const DropdownItem = styled.div`
  width: 114px;
  padding: 17px 12px;
  cursor: pointer;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.9px;
  text-align: left;

  &:hover {
    background-color: #f0f0f0;
  }

  ${(props) =>
    props.selected &&
    ` color: #000000;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 700;
    line-height: 17.9px;
    text-align: left;`}
`;

const ArrowDown = ({ color = "black" }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.2 8.43333L2.7 5.1C2.20557 4.44076 2.67596 3.5 3.5 3.5L8.5 3.5C9.32405 3.5 9.79443 4.44076 9.3 5.1L6.8 8.43333C6.4 8.96667 5.6 8.96667 5.2 8.43333Z"
      fill={color}
    />
  </svg>
);

// Dropdown component
const DropDownTradeStyle = ({
  options,
  disabled = false,
  onClick = () => {},
  selected = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedOption, setSelectedOption] = useState(selected || options[0]);
  const dropdownRef = useRef(null);

  const handleOptionClick = (option) => {
    setIsOpen(false);
    onClick(option);
  };

  // useEffect(() => {
  //   setSelectedOption(options[0]);
  // }, [options]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <DropdownWrapper ref={dropdownRef}>
      <DropdownButton
        onClick={() => {
          if (!disabled) {
            setIsOpen(!isOpen);
          }
        }}
      >
        {selected}
        <ArrowDown color="#4B4C4D" />
      </DropdownButton>
      <DropdownMenu isOpen={isOpen}>
        {options.map((option) => (
          <DropdownItem
            key={option}
            onClick={() => handleOptionClick(option)}
            selected={option === selected ? "selected" : ""}
          >
            {option}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </DropdownWrapper>
  );
};

export default DropDownTradeStyle;
