import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const BackgroundWrapper = styled.div`
  background-color: #f0f2f5;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  color: #000;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Title = styled.h1`
  font-family: Pretendard, sans-serif;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.8;
  margin-bottom: 30px;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  padding: 10px 15px;
  border-radius: 8px;
  background-color: #ff3e61;
  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ff2545;
  }
`;

const NotFound = () => {
  return (
    <BackgroundWrapper>
      <ContentWrapper>
        <Title>404 - 페이지를 찾을 수 없습니다</Title>
        <Description>
          죄송합니다. 요청하신 페이지를 찾을 수 없습니다. 주소를 다시 확인해
          주시거나, 아래 버튼을 클릭하여 홈페이지로 이동해 주세요.
        </Description>
        <StyledLink to="/">홈으로 돌아가기</StyledLink>
      </ContentWrapper>
    </BackgroundWrapper>
  );
};

export default NotFound;
