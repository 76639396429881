import ToggleComponet from "components/ToggleComponet";
import { css } from "styled-components";

import { useAnalysisStockPerformanceQuery } from "hooks/queries/useMyDetail";
import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { ReactComponent as ArrowDownIcon } from "assets/images/ico_arrow_dropdown.svg";
import StockSelectModal from "components/Modals/StockSelectModal";
import styled from "styled-components";
import { useSelectedCompetition } from "utils/store";
import {
  convertToNearWon,
  convertToPercentage,
  convertToTwoDecimalPlaces,
  formatSec,
  unescapeHtml,
} from "utils/utils";
const Section = styled.div``;

const CardWrapper = styled.div`
  height: 500px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;
  width: 100%;
  margin: 32px 0;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  display: flex;
  flex-direction: column;

  .title {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    text-align: left;
    padding: 24px 24px 16px;
    cursor: pointer;
  }

  .toggle {
    width: fit-content;
    padding: 0 24px 12px;
  }

  .graph {
    height: 360px;
  }

  svg {
    margin-left: 4px;
  }
`;

const HistoryCardWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;
  width: 100%;
  margin: 32px 0;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  display: flex;
  flex-direction: column;

  .title {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    text-align: left;
    padding: 24px 24px 16px;
  }

  .toggle {
    width: fit-content;
    padding: 0 24px;
  }

  .graph {
    height: 360px;
  }
`;

const GraphCard = ({ graphData = {}, isLoading = true, title = "" }) => {
  const [currentType, setCurrentType] = useState("ret");

  return (
    <CardWrapper>
      <div className="title">{title}</div>
      <div className="toggle">
        <ToggleComponet
          onClick={(type) => setCurrentType(type)}
          labels={[
            { name: "수익률", type: "ret" },
            { name: "수익금", type: "pnl" },
          ]}
        />
      </div>
      {isLoading ? (
        <div>로딩중</div>
      ) : (
        <div className="graph">
          <ResponsiveContainer>
            <BarChart
              data={graphData?.performance.map((item) => {
                return {
                  ...item,
                  ret:
                    currentType === "ret"
                      ? parseFloat(item.ret)
                      : parseFloat(item.pnl),
                };
              })}
              layout="vertical"
              barCategoryGap={1}
              margin={{ top: 0, right: 50, left: 0, bottom: 0 }}
            >
              <XAxis type="number" hide />
              <YAxis
                type="category"
                width={150}
                padding={{ left: 20 }}
                dataKey="name"
                tickFormatter={(value) => `${unescapeHtml(value)}`}
              />
              <Bar dataKey="ret" fill="#FF3D6C" barSize={8}>
                <LabelList
                  dataKey="ret"
                  position="right"
                  formatter={(value) => convertToPercentage(value)}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </CardWrapper>
  );
};

const HistoryCellStyle = css`
  text-align: right;

  &:nth-child(1) {
    text-align: left;
    width: calc(100px - 24px);
  }

  &:nth-child(2) {
    width: calc(70px - 24px);
  }

  &:nth-child(3) {
    width: calc(90px - 24px);
  }

  &:nth-child(4) {
    width: calc(100px - 24px);
  }

  &:nth-child(5) {
    width: calc(140px - 24px);
  }

  &:nth-child(6) {
    width: calc(140px - 24px);
  }

  &:nth-child(7) {
    width: calc(90px - 24px);
  }

  &:nth-child(8) {
    width: calc(110px - 24px);
  }
  &:nth-child(9) {
    width: calc(100px - 24px);
  }
  &:nth-child(10) {
    width: calc(140px - 24px);
  }
`;

const TableWrapper = styled.div`
  padding: 0 20px 20px;

  thead,
  tbody tr {
    display: table;
    width: 100%;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const StyledTableHeaderCell = styled.th`
  ${HistoryCellStyle}
  height: 36px;
  padding: 0 12px;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.32px;

  border-bottom: 1px solid #ebedf5;
`;

const StyledTableCell = styled.td`
  ${HistoryCellStyle}
  height: 36px;
  padding: 0 12px;
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;

  border-bottom: 1px solid #f2f4f7;

  color: ${(props) =>
    props?.value ? (props.value > 0 ? "#FF2727" : "#4C67FF") : "#323233"};
`;

const TableBody = styled.tbody`
  display: block;
  max-height: 505px;
  overflow-y: auto;
`;

const StyledTableRow = styled.tr`
  &:hover {
    cursor: pointer;
  }
`;

const ScrollTable = ({ data, handleStockCode = () => {} }) => {
  const [sortedData, setSortedData] = useState(data);

  const handleSort = (key) => {
    const sorted = [...data].sort((a, b) => {
      if (key === "date") {
        return new Date(a.date) - new Date(b.date);
      } else if (key === "ret") {
        return parseFloat(a.ret) - parseFloat(b.ret);
      }
      return 0;
    });
    setSortedData(sorted);
  };

  return (
    <TableWrapper>
      <StyledTable>
        <thead>
          <StyledTableHeaderCell onClick={() => handleSort("date")}>
            종목
          </StyledTableHeaderCell>
          <StyledTableHeaderCell>거래</StyledTableHeaderCell>
          <StyledTableHeaderCell>거래 승률</StyledTableHeaderCell>
          <StyledTableHeaderCell onClick={() => handleSort("ret")}>
            수익률
          </StyledTableHeaderCell>
          <StyledTableHeaderCell>수익금</StyledTableHeaderCell>
          <StyledTableHeaderCell>거래 대금</StyledTableHeaderCell>
          <StyledTableHeaderCell>평균 거래</StyledTableHeaderCell>
          <StyledTableHeaderCell>평균 보유시간</StyledTableHeaderCell>
        </thead>
        <TableBody>
          {sortedData &&
            sortedData?.map((row, index) => (
              <StyledTableRow
                key={index}
                onClick={() =>
                  handleStockCode({ code: row?.code, name: row?.name })
                }
              >
                <StyledTableCell>{unescapeHtml(row?.name)}</StyledTableCell>
                <StyledTableCell>{row?.n_tr + "번"}</StyledTableCell>
                <StyledTableCell>
                  {convertToPercentage(row.win_rate)}
                </StyledTableCell>
                <StyledTableCell value={row.ret}>
                  {convertToPercentage(row.ret)}
                </StyledTableCell>
                <StyledTableCell>{convertToNearWon(row.pnl)}</StyledTableCell>
                <StyledTableCell>{convertToNearWon(row.amt)}</StyledTableCell>
                <StyledTableCell>
                  {convertToTwoDecimalPlaces(row?.avg_n_trx) + "번"}
                </StyledTableCell>
                <StyledTableCell>
                  {formatSec(row?.avg_hold_time)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </StyledTable>
    </TableWrapper>
  );
};

const HistoryTableCard = ({
  historyData = {},
  isLoading = true,
  title = "성과 History",
  handleStockCode = () => {},
}) => {
  return (
    <HistoryCardWrapper>
      <div className="title">{title}</div>
      {isLoading ? (
        <div>로딩중</div>
      ) : (
        <div className="table">
          {/* <ScrollTableBody data={historyData} /> */}
          <ScrollTable data={historyData} handleStockCode={handleStockCode} />
        </div>
      )}
    </HistoryCardWrapper>
  );
};

const StockGanttChart = (data = {}) => {};

const StockTable = ({ data = [], cuid = "", code = "" }) => {
  const [sortedData, setSortedData] = useState(data || []);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const handleSort = (key) => {
    // const sorted = [...data].sort((a, b) => {
    //   if (key === "date") {
    //     return new Date(a.date) - new Date(b.date);
    //   } else if (key === "ret") {
    //     return parseFloat(a.ret) - parseFloat(b.ret);
    //   }
    //   return 0;
    // });
    // setSortedData(sorted);
  };

  return (
    <TableWrapper>
      <StyledTable>
        <thead>
          <StyledTableHeaderCell
            // onClick={() => handleSort("date")}
            style={{ width: 38 }}
          >
            분류
          </StyledTableHeaderCell>
          <StyledTableHeaderCell style={{ width: 162 }}>
            날짜
          </StyledTableHeaderCell>
          <StyledTableHeaderCell>수익률</StyledTableHeaderCell>
          <StyledTableHeaderCell>수익금</StyledTableHeaderCell>
          <StyledTableHeaderCell>거래 대금</StyledTableHeaderCell>
          <StyledTableHeaderCell>진입-청산</StyledTableHeaderCell>
          <StyledTableHeaderCell>거래</StyledTableHeaderCell>
          <StyledTableHeaderCell>평균거래시간</StyledTableHeaderCell>
        </thead>
        <TableBody style={{ maxHeight: 378 }}>
          {sortedData?.map((row, index) => (
            <StyledTableRow
              key={index}
              onClick={() => {
                window.open(
                  `/note?cuid=${cuid}&code=${code}&date=${row.daterange}`,
                  "newwindow",
                  "width=1300, height=1200"
                );
              }}
            >
              <StyledTableCell style={{ width: 38 }}>
                {row.type}
              </StyledTableCell>
              <StyledTableCell style={{ width: 162 }}>
                {row.daterange}
              </StyledTableCell>
              <StyledTableCell value={row.ret}>
                {convertToPercentage(row.ret)}
              </StyledTableCell>
              <StyledTableCell>{convertToNearWon(row.pnl)}</StyledTableCell>
              <StyledTableCell>{convertToNearWon(row.amt)}</StyledTableCell>
              <StyledTableCell>{row.n_tr + "건"}</StyledTableCell>
              <StyledTableCell>{row.n_trx + "건"}</StyledTableCell>
              <StyledTableCell>{formatSec(row.avg_hold_time)}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableWrapper>
  );
};

const StockCard = ({
  cuid = "",
  stockData = {},
  isLoading = true,
  stockCode,
  handleStockCode,
  noteId = "",
  stockCodeList = [],
}) => {
  const [currentType, setCurrentType] = useState("stocks");
  const [graphData, setGraphData] = useState();
  const [tableData, setTableData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { code, name } = stockCode || {};

  function convertStructure(data) {
    const newData = [];
    const newObject = {};
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        newObject[key] = data[key][0];
      }
    }
    newData.push(newObject);
    return newData;
  }

  useEffect(() => {
    if (stockCode) {
      setGraphData(convertStructure(stockData?.chart[code]));
      setTableData(stockData?.notes[code]);
    }
  }, [stockCode]);
  return (
    <CardWrapper style={{ height: "fit-content" }}>
      {isLoading ? (
        <div>로딩중</div>
      ) : (
        <>
          <div
            className="title"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            {unescapeHtml(name)}
            <ArrowDownIcon />
          </div>
          <StockSelectModal
            stockList={stockCodeList}
            onSubmit={handleStockCode}
            isOpen={isModalOpen}
            onCancel={() => {
              setIsModalOpen(false);
            }}
            selectedStock={stockCode}
          />
          <div className="toggle">
            <ToggleComponet
              onClick={(type) => setCurrentType(type)}
              labels={[
                { name: "성과 상세", type: "stocks" },
                { name: "날짜별 성과", type: "chart" },
              ]}
            />
          </div>
          {currentType === "chart" ? (
            <div className="graph">
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  width={500}
                  height={400}
                  // FIXME
                  data={tableData}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis dataKey="daterange" />
                  <YAxis />

                  <Tooltip cursor={{ fill: "transparent" }} />
                  <Legend verticalAlign="top" align="left" height={33} />
                  <Bar dataKey="ret" barSize={20} fill="#FFD5E0" />
                  <Line
                    type="monotone"
                    dataKey="cum_ret"
                    stroke="#FF3D6C"
                    dot={false}
                    activeDot={false}
                  />
                  <ReferenceLine y={0} stroke="#000" />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <StockTable data={tableData} cuid={cuid} code={code} />
          )}
        </>
      )}
    </CardWrapper>
  );
};

const MyDetailStockSection = ({ cuid = "" }) => {
  const { selectedCompetition } = useSelectedCompetition((state) => state);

  const {
    data: graphData,
    isLoading: isGraphDataLoaing,
    isError: isGraphDataError,
  } = useAnalysisStockPerformanceQuery({
    cid: selectedCompetition?.cid,
    cuid,
  });

  const [currentStockCode, setCurrentStockCode] = useState();

  // graphData.code2name을 추가하였습니다.

  useEffect(() => {
    if (graphData) {
      const maxStockCode = graphData.hist_stock.reduce((prev, current) => {
        return prev.ret > current.ret ? prev : current;
      });

      setCurrentStockCode({ code: maxStockCode.code, name: maxStockCode.name });
    }
  }, [graphData]);

  return (
    <Section>
      <GraphCard
        title="종목별 성과"
        graphData={graphData}
        isLoading={isGraphDataLoaing}
      />
      <HistoryTableCard
        title="종목별 성과 History"
        historyData={graphData?.hist_stock}
        isLoading={isGraphDataLoaing}
        handleStockCode={setCurrentStockCode}
      />
      <StockCard
        title="날짜"
        cuid={cuid}
        stockData={graphData?.note_change}
        isLoading={isGraphDataLoaing}
        stockCode={currentStockCode}
        stockName={currentStockCode?.name}
        handleStockCode={setCurrentStockCode}
        stockCodeList={graphData?.code2name}
      />
    </Section>
  );
};

export default MyDetailStockSection;
