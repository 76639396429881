import { useRankerPerformanceQuery } from "hooks/queries/useCompetitionQuery";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { useSelectedCompetition } from "utils/store";
import { convertToPercentage } from "utils/utils";

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
`;

const PerformanceSection = ({}) => {
  const { selectedCompetition } = useSelectedCompetition((state) => state);
  const { data, isLoading } = useRankerPerformanceQuery({
    cid: selectedCompetition?.cid,
  });
  const CHART_COLOR = {
    kospi: "#272831",
    kosdaq: "#7A7A7A",
    total: "#972D00",
    "1억": "#FF5D18",
    "5천": "#8DCA87",
    "3천": "#B580E0",
    "1천": "#FFDF37",
    "1백": "#33CCED",
  };

  return (
    <TagWrapper>
      {data && (
        <LineChart
          width={1128}
          height={350}
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis
            tickFormatter={(value) => {
              return `${value}%`;
            }}
          />
          <Tooltip
            formatter={(value) => {
              return ` ${convertToPercentage(value)}`;
            }}
            itemSorter={(item) => -item.value}
          />
          <Legend />
          <Line dot={false} dataKey="kospi" stroke={CHART_COLOR["kospi"]} />
          <Line dot={false} dataKey="kosdaq" stroke={CHART_COLOR["kosdaq"]} />
          <Line dot={false} dataKey="total" stroke={CHART_COLOR["total"]} />
          <Line dot={false} dataKey="1억" stroke={CHART_COLOR["1억"]} />
          <Line dot={false} dataKey="5천" stroke={CHART_COLOR["5천"]} />
          <Line dot={false} dataKey="3천" stroke={CHART_COLOR["3천"]} />
          <Line dot={false} dataKey="1천" stroke={CHART_COLOR["1천"]} />
          <Line dot={false} dataKey="1백" stroke={CHART_COLOR["1백"]} />
        </LineChart>
      )}
    </TagWrapper>
  );
};

export default PerformanceSection;
