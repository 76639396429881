import useHttpClient, { api_v_url } from "hooks/useHttpClient";
import { useQuery } from "react-query";

function useAlphaNoteQuery({ note_id = "" }) {
  const httpClient = useHttpClient(api_v_url);
  return useQuery({
    queryKey: ["note", { note_id }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/note?cuid=${note_id.cuid}&code=${note_id.code}&date=${note_id.date}`
      );
      return queryResult?.data;
    },
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaNoteChartQuery({ note_id = {} }) {
  const httpClient = useHttpClient(api_v_url);
  return useQuery({
    queryKey: ["note-get_min_chart", { note_id }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/note/get_min_chart?cuid=${note_id.cuid}&code=${note_id.code}&date=${note_id.date}&unit=${note_id.unit}`
      );
      return queryResult?.data;
    },
    staleTime: 10 * 60 * 60 * 1000,
  });
}

export { useAlphaNoteChartQuery, useAlphaNoteQuery };
