import AutoScroll from "embla-carousel-auto-scroll";
import useEmblaCarousel from "embla-carousel-react";
import { StockCard } from "pages/Home/AlphaHome";
import { useEffect } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  .embla {
    overflow: hidden;
  }
  .embla__container {
    display: flex;
    box-shadow: 10px 10px 40px 0px #3f5ba30d;
  }
  .embla__slide {
    flex: 0 0 360px;
    min-width: 0;
  }
`;

const EmblaCarousel = (props) => {
  const { slides, options } = props;
  const multipleSlides = slides.concat(slides);
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    AutoScroll({
      playOnInit: true,
      speed: 1,
      startDelay: 0,
      stopOnFocusIn: false,
      stopOnInteraction: false,
    }),
  ]);
  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll;
    if (!autoScroll) return;
  }, [emblaApi]);

  return (
    <Wrapper>
      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {multipleSlides.map((item, index) => (
              <div className="embla__slide" key={index}>
                <div className="embla__slide__number">
                  <span>
                    <StockCard key={index} {...item} total={500} />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default EmblaCarousel;
