import useHttpClient, { api_v_url } from "hooks/useHttpClient";
import { useQuery } from "react-query";

function useAlphaStockHotQuery({ cid = "", date = "" }) {
  const httpClient = useHttpClient(api_v_url);
  return useQuery({
    queryKey: ["stock-hot", { cid, date }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/stock/hot?cid=${cid}&date=${date}`
      );
      return queryResult?.data;
    },
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaStockGetChartQuery({ code, date, unit, name}) {
  const httpClient = useHttpClient(api_v_url);
  return useQuery({
    queryKey: ["stock-get_min_chart", { code, date, unit, name}],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/stock/get_min_chart?name=${name}&code=${code}&date=${date}&unit=${unit}`
      );
      return queryResult?.data;
    },
    enabled: !!code && !!date && !!unit,
    staleTime: 10 * 60 * 60 * 1000,
  });
}

export { useAlphaStockGetChartQuery, useAlphaStockHotQuery };
