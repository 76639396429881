import useHttpClient, { api_v_url } from "hooks/useHttpClient";
import { useQuery } from "react-query";

function useAlphaRankQuery({ cid = "", type = "league" }) {
  const httpClient = useHttpClient(api_v_url);

  // type can be league, style, recommend
  // use case is league, style, recommend
  const qk = `trader-${type}_ranker`;
  const apiurl = `/v1b/trader/${type}_ranker?cid=${cid}`;

  return useQuery({
    queryKey: [qk, { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        // `${apiurl}?cid=${cid}`
        `${apiurl}`
      );

      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaLeagueRankQuery({ cid = "" }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-league_rank", { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/trader/league_rank?cid=${cid}`
      );

      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}
function useAlphaStyleRankQuery({ cid = "" }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-style_rank", { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/trader/style_rank?cid=${cid}`
      );

      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}
function useAlphaRecommendRankQuery({ cid = "" }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-recommend_rank", { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/trader/recommend_rank?cid=${cid}`
      );

      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

export {
  useAlphaLeagueRankQuery,
  useAlphaRankQuery,
  useAlphaStyleRankQuery,
  useAlphaRecommendRankQuery,
};
