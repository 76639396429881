import AlphaTraderCard from "components/AlphaTraderCard";
import TradeToggle from "components/TradeToggle";
import TraderLeagueTableSection from "components/TraderLeagueTableSection";
import { useAlphaRankQuery } from "hooks/queries/useAlphaTraderListQuery";
import { useState } from "react";
import styled from "styled-components";
import { LEAGUE_LABELS, TRADE_STYLE, TRADE_RECOMMEND } from "utils/consts";
import { useSelectedCompetition } from "utils/store";

const SectionWrapper = styled.div`
  margin-bottom: 30px;
`;

const Wrapper = styled.div`
  .toggle-wrapper {
    margin-top: 20px;
  }
`;

const CardSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const DeckSection = styled.div`
  width: 100%;
`;

const DeckTitle = styled.h2`
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 600;
  margin-top: -18px;
  margin-bottom: 12px;
  color: #333;
  text-align: left;
`;

const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const ToggleWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const AlphaTraderRanking = ({ type = "league" }) => {
  const category = (() => {
    switch (type) {
      case "league":
        return LEAGUE_LABELS;
      case "style":
        return TRADE_STYLE;
      case "recommend":
        return TRADE_RECOMMEND;
      default:
        return [];
    }
  })();

  const [league, setLeague] = useState(category[0]?.type || "");
  const { selectedCompetition } = useSelectedCompetition((state) => state);

  const { data: rankerData, refetch } = useAlphaRankQuery({
    league,
    cid: selectedCompetition?.cid,
    type,
  });

  const handleCardClick = (id) => {
    window.open(`/trader/${id}`, "_blank");
  };

  console.log("rankerData", rankerData);

  const renderContent = () => {
    if (type === "recommend") {
      return (
        <>
          {rankerData?.map(
            (deck, deckIndex) =>
              deck.cards.length > 0 && (
                <CardSection>
                  <DeckSection key={deckIndex}>
                    <DeckTitle>{deck.title}</DeckTitle>
                    <CardsWrapper>
                      {deck.cards.slice(0, 3).map((data, index) => (
                        <AlphaTraderCard
                          key={`${deckIndex}-${index}`}
                          type={type}
                          data={data}
                          index={index + 1}
                          width={360 - 32 - 2}
                          onClick={() => handleCardClick(data?.cuid || "")}
                          updateIsLiked={() => refetch()}
                        />
                      ))}
                    </CardsWrapper>
                  </DeckSection>
                </CardSection>
              )
          )}
        </>
      );
    }

    return (
      <>
        <CardSection>
          {rankerData &&
            rankerData[league]
              .slice(0, 3)
              .map((data, index) => (
                <AlphaTraderCard
                  key={index}
                  type={type}
                  data={data}
                  index={data?.sequence || index + 1}
                  width={360 - 32 - 2}
                  onClick={() => handleCardClick(data?.cuid || "")}
                  updateIsLiked={() => refetch()}
                />
              ))}
        </CardSection>
        <SectionWrapper>
          {rankerData && (
            <TraderLeagueTableSection
              type={type}
              dataList={rankerData[league].slice(3)}
              updateIsLiked={() => refetch()}
            />
          )}
        </SectionWrapper>
      </>
    );
  };

  return (
    <Wrapper>
      <ToggleWrapper>
        <TradeToggle
          setToggleState={(type) => {
            setLeague(type);
          }}
          tabNames={category.map((item) => item?.name) || []}
          toggleState={category.map((item) => item?.type) || []}
        />
      </ToggleWrapper>
      {renderContent()}
    </Wrapper>
  );
};

export default AlphaTraderRanking;
