import {
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { convertToPercentage, formatSec } from "utils/utils";
const CardWrapper = styled.div`
  border: 1px solid #ebedf5;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;

  .p {
    color: #ff2727;
  }

  .n {
    color: #4c67ff;
  }
`;

const ScatterWrapper = styled.div`
  margin-top: 60px;
  .is-title {
    font-family: Pretendard;
    font-size: 22px;
    font-weight: 600;
    line-height: 26.25px;
    text-align: left;

    color: #141414;
  }
  .is-subtitle {
    margin-top: 8px;
    margin-bottom: 20px;
    color: #7d7e80;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.9px;
    text-align: left;
  }
`;

export const InfoStyleScatterSection = ({ data }) => {
  return (
    <ScatterWrapper>
      <div className="is-title">보유 시간 별 수익률</div>
      <div className="is-subtitle">
        수익이 잘 나는 스타일을 눈으로 직접 확인하고, 해당 스타일률
        집중해보세요.
      </div>

      <CardWrapper>
        <ScatterCard data={data} />
      </CardWrapper>
    </ScatterWrapper>
  );
};

const ScatterCardWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;

  padding: 20px 20px 11px;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  .title {
    color: #141414;
  }

  .chart {
    height: 334px;
    width: 100%;
    margin-top: 17px;
  }
`;

const ScatterCard = (data) => {
  const renderScatterShape = (props) => {
    const { cx, cy, payload, fill } = props;

    // domain={[60 * 5, 60 * 30, 3600 * 3, 3600 * 24]}

    let rangeFill = fill;
    if (payload.hold_time < 60 * 5) {
      rangeFill = "#8268EB";
    } else if (payload.hold_time < 60 * 30) {
      rangeFill = "#4A9B9A";
    } else if (payload.hold_time < 3600 * 3) {
      rangeFill = "#DA6095";
    } else if (payload.hold_time < 3600 * 24) {
      rangeFill = "#4393E2";
    } else {
      rangeFill = "#FFCC38";
    }

    return (
      <circle
        cx={cx}
        cy={cy}
        r={7}
        fill={rangeFill}
        stroke="#000"
        strokeWidth={1.5}
        fillOpacity={0.3}
      />
    );
  };

  return (
    <ScatterCardWrapper>
      <div className="title">보유시간별 성과</div>
      <div className="chart">
        <ResponsiveContainer width="100%">
          <ScatterChart
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid />
            <XAxis
              type="number"
              dataKey="hold_time"
              name="hold_time"
              domain={["dataMin", 3600 * 24]}
              scale="log"
              ticks={[60 * 5, 60 * 30, 3600 * 3, 3600 * 24]}
              tickFormatter={(tickItem) => `${tickItem / 60}분`}
            />
            <YAxis type="number" dataKey="ret" name="ret" unit="%" />
            <Tooltip
              cursor={{ strokeDasharray: "3 3" }}
              content={({ payload }) => (
                <div
                  style={{
                    backgroundColor: "#ffffff",
                    border: "1px solid #ebebeb",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <p>{`종목: ${payload[0]?.payload?.name} (${payload[0]?.payload?.code})`}</p>
                  <p>{`보유시간: ${formatSec(
                    payload[0]?.payload?.hold_time
                  )}`}</p>
                  <p>{`수익률: ${convertToPercentage(
                    payload[0]?.payload?.ret
                  )}`}</p>
                  {/* 추가하려는 정보를 여기에 작성하세요 */}
                </div>
              )}
            />
            <ReferenceLine y={0} stroke="#000" />
            <Scatter
              name="A school"
              data={data.data}
              fill="#8884d8"
              shape={renderScatterShape}
              onClick={(data) => {
                window.open(
                  `/note?cuid=${data.note_id.cuid}&code=${data.note_id.code}&date=${data.note_id.date}`,
                  "newWindow",
                  "width=1300,height=1200"
                );
              }}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </ScatterCardWrapper>
  );
};

export default InfoStyleScatterSection;
