import base64 from "base-64";
import {
  useAlphaMyTraderQuery,
  useAlphaTraderDetailQuery,
} from "hooks/queries/useAlphaTraderDetailQuery";
import LoginRequired from "pages/Login/LoginRequired";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useNotice, useSelectedCompetition } from "utils/store";
import AlphaDetailEmptyNote from "./AlphaDetailEmptyNote";
import AlphaDetailHome from "./AlphaDetailHome";
import AlphaDetailInfo from "./AlphaDetailInfo";
import AlphaDetailMyNote from "./AlphaDetailMyNote";
import AlphaDetailNote from "./AlphaDetailNote";
import TitleSection from "./TitleSection";

const HomeWrapper = styled.div`
  width: 100%;
  padding-bottom: 127px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #f9fafc;

  .section-bg {
    width: 100%;
    background: #16191f;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .fullWidth {
    width: 1128px;
  }

  .datainfo {
    padding: 12px 0;
  }

  .login-wrapper {
    padding: 100px;
  }
`;

const TraderDetail = () => {
  const { hash } = window.location;
  const { cuid } = useParams();
  const { pathname } = window.location;

  const isMyNote = pathname === "/mynote";

  const [type, setType] = useState(
    hash.replace(/^#/, "").split("?")[0] || "home"
  );
  const [isHold, setIsHold] = useState(false);
  const { selectedCompetition } = useSelectedCompetition((state) => state);

  const [lastUpdate, setLastUpdate] = useState("");
  const [myCuid, setMyCuid] = useState(cuid || "");

  useEffect(() => {
    setIsHold(hash?.split("?")[1] === "hold=true");
    setType(hash.replace(/^#/, "").split("?")[0] || "home");
  }, [hash]);

  const {
    data: summaryPerformanceData,
    isLoading,
    refetch: refetchSummary,
  } = useAlphaTraderDetailQuery({
    cuid: myCuid,
  });

  const {
    data: myPerformanceData,
    isLoading: isMyLoading,
    error: myError,
    refetch: refetchMy,
  } = useAlphaMyTraderQuery();

  useEffect(() => {
    refetchSummary();
  }, [cuid, myCuid]);

  const { isNoticeShow } = useNotice((state) => state);
  const [isNoticeOpen, setNoticeOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const gotoLogin = () => {
    const currentPath = window.location.pathname;
    localStorage.setItem("redirectAfterLogin", currentPath);
    window.location.href = "/login";
  };

  const refetchAllData = () => {
    refetchSummary({ cuid: myCuid });
    refetchMy();
  };

  useEffect(() => {
    window.location.hash = type;
  }, [type]);

  useEffect(() => {
    if (isMyNote && !myCuid) {
      setMyCuid(myPerformanceData?.current?.cuid);
    }
  }, [myPerformanceData]);

  useEffect(() => {
    if (isMobile && isNoticeShow === false) {
      setNoticeOpen(true);
    }
  }, []);

  useEffect(() => {
    try {
      if (myError?.response?.status === 401) {
        // gotoLogin();
      }
    } catch (e) {
      console.error("Error updating like status:", e.message);
    }
  }, [myError]);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    //  check is token expired
    if (token) {
      const payload = JSON.parse(base64.decode(token.split(".")[1]));
      const currentTime = Date.now() / 1000;

      if (payload.exp < currentTime) {
        localStorage.removeItem("access_token");
      } else {
        setIsLoggedIn(true);
      }
    }
  }, [localStorage.getItem("access_token")]);

  if (isMyNote && !isLoggedIn) {
    return (
      <HomeWrapper>
        <div className="login-wrapper">
          <LoginRequired />
        </div>
      </HomeWrapper>
    );
  }

  return (
    <HomeWrapper>
      {/* <NoticeModal
        isOpen={isNoticeOpen}
        onCancel={() => {
          setNoticeOpen(false);
        }}
      /> */}

      <div className="section-bg">
        <div className="fullWidth">
          {isMyNote
            ? !isMyLoading && (
                <TitleSection
                  data={myPerformanceData || {}}
                  setTab={setType}
                  selectedTab={type}
                  isMyNote={isMyNote}
                  setMyCuid={setMyCuid}
                  refetchMy={refetchAllData}
                  myCuid={myCuid}
                />
              )
            : !isLoading &&
              summaryPerformanceData && (
                <TitleSection
                  data={summaryPerformanceData || {}}
                  setTab={setType}
                  selectedTab={type}
                  myCuid={myCuid}
                />
              )}
        </div>
      </div>
      <div className="fullWidth">
        {type === "home" &&
          (myCuid ? (
            <AlphaDetailHome cuid={myCuid} setLastUpdate={setLastUpdate} />
          ) : (
            <AlphaDetailEmptyNote setType={setType} />
          ))}
        {type === "detail" && (
          <AlphaDetailInfo cuid={myCuid} lastUpdate={lastUpdate} />
        )}
        {type === "notes" && (
          <AlphaDetailNote
            cuid={myCuid}
            lastUpdate={lastUpdate}
            isHold={isHold}
          />
        )}
        {type === "setting" && <AlphaDetailMyNote cuid={myCuid} />}
      </div>
      {/* <Tooltip id={"detail-tooltip"} place={"bottom"} /> */}
    </HomeWrapper>
  );
};

export default TraderDetail;
