import {
  useTopNoteChartQuery,
  useTopNoteQuery,
} from "hooks/queries/useCompetitionQuery";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useSelectedCompetition } from "utils/store";
import { convertToPercentage, unescapeHtml } from "utils/utils";
import TraderCard from "./TraderCard";

import { ChartArea } from "pages/TraderDetail/StockDetail";

const TagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
`;

const TraderSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
`;

const ChartSection = ({ chartInfo }) => {
  const containerRef = useRef();
  const [chartSetup, setChartSetup] = useState(null);

  const { selectedCompetition } = useSelectedCompetition((state) => state);

  const { data: graphData } = useTopNoteChartQuery({
    cid: selectedCompetition?.cid,
    sequence: chartInfo?.index || 0,
  });

  // useEffect(() => {
  //   if (containerRef.current && graphData) {
  //     setupChart(containerRef.current, chartSetup, setChartSetup, {
  //       ...graphData.chart,
  //       markers: graphData.markers,
  //     });
  //   }
  // }, [graphData]);

  // return <ChartContainer ref={containerRef} />;
  return (
    <ChartArea
      chartData={graphData?.chart}
      markersData={graphData?.markers}
      name={unescapeHtml(chartInfo?.name) || ""}
    />
  );
};

const JournalSection = ({}) => {
  const { selectedCompetition } = useSelectedCompetition((state) => state);

  const { data: topNoteData } = useTopNoteQuery({
    cid: selectedCompetition?.cid,
  });

  const [chartInfo, setChartInfo] = useState({
    index: 0,
    name: "",
  });

  useEffect(() => {
    if (topNoteData) {
      setChartInfo({
        index: 0,
        name: topNoteData[0]?.stock_name,
      });
    }
  }, [topNoteData]);

  const handleReportClick = (index) => {
    setChartInfo({
      index: index,
      name: topNoteData[index]?.stock_name,
    });
  };

  return (
    <TagWrapper>
      <TraderSection>
        {topNoteData &&
          topNoteData.slice(0, 3).map((data, index) => (
            <div onClick={() => handleReportClick(index)} key={"note-" + index}>
              <TraderCard
                key={index}
                active={(index === chartInfo?.index).toString()}
                data={data}
                index={index + 1}
                infoData={[
                  data?.daterange,
                  unescapeHtml(data?.stock_name),
                  convertToPercentage(data?.win_rate),
                  data?.n_tr + "회",
                ]}
              />
            </div>
          ))}
      </TraderSection>
      <ChartSection chartInfo={chartInfo} />
    </TagWrapper>
  );
};

export default JournalSection;
