import styled from "styled-components";

import BookmarkIcon from "assets/images/ico_bookmark.png";

import { useState } from "react";
import { TRADE_STYLE_TYPE } from "utils/consts";
import {
  addCommaToInt,
  convertToNearWon,
  convertToPercentage,
} from "utils/utils";
import AlphaStyleTag from "./AlphaStyleTag";
import TraderLike from "./Reaction/TraderLike";
import { SvgStkImg } from "./StockIcon";

const TagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
`;

const StyledTableRow = styled.tr`
  &:hover {
    cursor: pointer;
  }
`;
const StyledTable = styled.table`
  width: 100%;
  margin-top: 24px;
  border-collapse: collapse;

  border-radius: 8px;
`;

const StyledHeader = styled.th`
  box-sizing: border-box;
  height: 36px;

  font-family: Pretendard;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: right;

  padding: 0 12px;
  color: #141414;
  background-color: #f7f8fa;

  &:nth-child(2) {
    text-align: left;
  }

  &:nth-child(1) {
    width: 56px;
  }
  &:nth-child(2) {
    width: 248px;
  }
  &:nth-child(3) {
    width: 90px;
  }
  &:nth-child(4) {
    width: 100px;
  }
  &:nth-child(5) {
    width: 100px;
  }
  &:nth-child(6) {
    width: 240px;
  }
  &:nth-child(7) {
    width: 150px;
  }
  &:nth-child(8) {
    width: 100px;
  }
  &:nth-child(9) {
    width: 44px;
  }
`;

const StyledCell = styled.td`
  box-sizing: border-box;
  height: 68px;
  padding: 0 12px;
  color: ${(props) => props?.color || "#141414"};
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  letter-spacing: 0em;
  text-align: right;
  border-bottom: 1px solid #e7e9ef;

  &:nth-child(1) {
    text-align: left;
  }

  &:nth-child(2) {
    text-align: left;
    font-weight: 500;
    color: #191a1a;
  }

  &:nth-child(1) {
    width: 56px;
  }
  &:nth-child(2) {
    width: 248px;
  }
  &:nth-child(3) {
    width: 90px;
  }
  &:nth-child(4) {
    width: 100px;
  }
  &:nth-child(5) {
    width: 100px;
  }
  &:nth-child(6) {
    width: 240px;
  }
  &:nth-child(7) {
    width: 150px;
  }
  &:nth-child(8) {
    width: 100px;
  }
  &:nth-child(9) {
    width: 44px;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
`;

const StockCell = styled.div`
  box-sizing: border-box;
  height: 68px;
  width: 240px;
  font-family: Pretendard;
  font-weight: 400;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;

  .stock {
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .stock-name {
      margin-left: 4px;
      font-size: 14px;
      line-height: 16.71px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .stock-ret {
      margin-left: 2px;
      font-size: 13px;
      line-height: 15.51px;
    }
  }

  .stock:nth-child(1) {
    margin-top: 0;
  }

  .red {
    color: ${(props) => (props.selected ? "#FF5857" : "#FF2727")};
  }
  .blue {
    color: ${(props) => (props.selected ? "#5E87FF" : "#4C67FF")};
  }
`;

const TRADER_COLUMS = [
  " ",
  "닉네임",
  "스타일",
  "종목수",
  "매매",
  "대표종목",
  "수익금",
  "수익률",
  "",
];

const ButtonWrapper = styled.div``;
const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  width: 200px;
  height: 44px;

  padding: 0px 10px 0px 10px;

  border-radius: 6px;
  border: 1px solid #e7e9ef;

  font-family: Pretendard;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.9px;

  &:hover {
    cursor: pointer;
  }
`;

const IndexStyle = styled.div`
  font-family: Montserrat;
  font-size: 13px;
  font-style: italic;
  font-weight: 700;
  line-height: 15.85px;
`;

const IconCell = styled.div`
  width: 18px;
  height: 18px;

  img {
    width: 18px;
    height: 18px;
  }
`;

const TraderLeagueTableSection = ({
  dataList = [],
  type = "",
  updateIsLiked = () => {},
}) => {
  const [page, setPage] = useState(7);
  const onClickMore = () => {
    setPage(Math.min(page + 50, dataList.length));
  };

  return (
    <TagWrapper>
      <StyledTable>
        <thead>
          <tr>
            {TRADER_COLUMS.map((column, index) => (
              <StyledHeader key={index}>
                {index === 2 && type === "style" ? "리그" : column}
              </StyledHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataList.slice(0, page).map((data, index) => {
            return (
              <StyledTableRow
                key={index}
                onClick={() => {
                  window.open(`/trader/${data?.cuid || ""}`, "_blank");
                }}
                className="trader-card"
              >
                <StyledCell>
                  <IndexStyle>{data.sequence} </IndexStyle>
                </StyledCell>
                <StyledCell>{data.nick}</StyledCell>
                <StyledCell>
                  <AlphaStyleTag type={data.style || data.league}>
                    {TRADE_STYLE_TYPE[data.style] || data.league}
                  </AlphaStyleTag>
                </StyledCell>
                <StyledCell>{addCommaToInt(data.n_stock) + "개"}</StyledCell>
                <StyledCell>{data.n_tr + "건"}</StyledCell>

                <StyledCell>
                  <StockCell>
                    {data?.stocks.slice(0, 2).map((stock, idx) => (
                      <div className="stock">
                        <SvgStkImg
                          code={stock?.code}
                          name={stock?.name}
                          size={16}
                        />
                        <div className="stock-name">{stock.name}</div>
                        <div
                          className={`stock-ret ${
                            stock.ret < 0 ? "blue" : "red"
                          }`}
                        >
                          {convertToPercentage(stock.ret)}
                        </div>
                      </div>
                    ))}
                  </StockCell>
                </StyledCell>
                <StyledCell>{convertToNearWon(data.pnl)}</StyledCell>
                <StyledCell>{convertToPercentage(data.ret)}</StyledCell>

                <StyledCell>
                  <IconCell style={{ display: "none" }}>
                    <img src={BookmarkIcon}></img>
                  </IconCell>
                  <IconCell>
                    <TraderLike
                      payload={{ cuid: data?.cuid }}
                      status={data?.like}
                      updateIsLiked={updateIsLiked}
                    />
                    {/* <img src={LikeIcon}></img> */}
                  </IconCell>
                </StyledCell>
              </StyledTableRow>
            );
          })}
        </tbody>
      </StyledTable>
      {page < dataList.length && (
        <ButtonWrapper>
          <Button onClick={onClickMore}>더보기</Button>
        </ButtonWrapper>
      )}
    </TagWrapper>
  );
};

export default TraderLeagueTableSection;
