import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import base64 from "base-64";

const LoginComplete = () => {
  const { access_token } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState("로그인 처리 중...");

  useEffect(() => {
    if (access_token) {
      var redirectAfterLogin = localStorage.getItem("redirectAfterLogin");

      localStorage.setItem("access_token", access_token);
      setMessage("로그인 성공!");
      setTimeout(() => {
        const payload = JSON.parse(base64.decode(access_token.split(".")[1]));

        // gtagmanager 에 유저 정보 전달
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "login",
          user_id: payload.id,
          arpa_id: payload.id,
        });

        if (redirectAfterLogin === "/logout") {
          redirectAfterLogin = "/";
        }

        // 부모 창이 있는 경우 부모 창을 리프레시
        if (window.opener) {
          window.opener.location.reload();
          window.close();
        } else {
          navigate(redirectAfterLogin || "/");
        }
      }, 1000); // 1초 지연 후 실행
    } else {
      console.error("Access token not found");
      setMessage("로그인 실패. 다시 시도해주세요.");
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    }
  }, [access_token, navigate]);

  return (
    <div>
      <h2>{message}</h2>
    </div>
  );
};

export default LoginComplete;
