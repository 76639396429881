import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { ReactComponent as ArrowLeft } from "assets/images/ico_arrow_left_s.svg";
import { ReactComponent as ArrowRight } from "assets/images/ico_arrow_right_s.svg";

export const usePrevNextButtons = (emblaApi) => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

const CalendarButton = styled.div`
  width: 26px;
  height: 24px;
  background: #ffffff;

  text-align: center;
  cursor: pointer;
  border: 1px solid #e7e9ef;

  &:hover {
    background: #f2f4f7;
  }

  &:active {
    background: #e7e9ef;
  }
`;

export const PrevButton = (props) => {
  const { children, ...restProps } = props;

  return (
    <CalendarButton className="left" {...restProps}>
      <ArrowLeft />
    </CalendarButton>
  );
};

export const NextButton = (props) => {
  const { children, ...restProps } = props;

  return (
    <CalendarButton className="right" {...restProps}>
      <ArrowRight />
    </CalendarButton>
  );
};
