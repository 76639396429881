import { TradingStyleCard } from "components/Card/TradingStyleCard";
import styled from "styled-components";
import { TRADE_STYLE_TYPE } from "utils/consts";
import { convertToNearWon, convertToPercentage } from "utils/utils";

const CardWrapper = styled.div`
  border: 1px solid #ebedf5;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  width: 360px;

  .p {
    color: #ff2727;
  }

  .n {
    color: #4c67ff;
  }
`;

const StyleWrapper = styled.div`
  margin-top: 60px;
  .is-title {
    font-family: Pretendard;
    font-size: 22px;
    font-weight: 600;
    line-height: 26.25px;
    text-align: left;

    color: #141414;
  }
  .is-subtitle {
    margin-top: 8px;
    margin-bottom: 20px;
    color: #7d7e80;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.9px;
    text-align: left;
  }
`;

const StyleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 360px);
  grid-template-rows: 440px;
  gap: 24px;
`;
export const InfoStyleSection = ({ data }) => {
  return (
    <StyleWrapper>
      <div className="is-title">스타일 별 수익률</div>
      <div className="is-subtitle">
        수익이 잘 나는 스타일을 눈으로 직접 확인하고, 해당 스타일에
        집중해보세요.
      </div>
      <StyleGrid>
        <CardWrapper>
          <TradingStyleCard
            data={{
              n_tr: {
                scalping: data.scalping.n_tr,
                day: data.day.n_tr,
                swing: data.swing.n_tr,
              },
            }}
            title="트레이딩 스타일"
            value={convertToPercentage(
              (Math.max(
                data?.scalping?.n_tr,
                data?.day?.n_tr,
                data?.swing?.n_tr
              ) /
                (data?.scalping?.n_tr + data?.day?.n_tr + data?.swing?.n_tr)) *
                100
            )}
            styleValue={
              TRADE_STYLE_TYPE[
                Object.keys(data).reduce((a, b) =>
                  data[a].n_tr > data[b].n_tr ? a : b
                )
              ]
            }
            type="pie"
            width={360}
          />
        </CardWrapper>
        <CardWrapper>
          <TradingStyleCard
            width={360}
            data={{
              scalpingData: data.scalping.ret,
              dayData: data.day.ret,
              swingData: data.swing.ret,
            }}
            title="스타일별 수익률"
            value={
              "평균 " +
              convertToPercentage(
                Math.max(data?.scalping?.ret, data?.day?.ret, data?.swing?.ret)
              )
            }
            styleValue={""}
            type="bar"
            formatter={(item) => convertToPercentage(item)}
          />
        </CardWrapper>
        <CardWrapper>
          <TradingStyleCard
            width={360}
            data={{
              scalpingData: data.scalping.pnl,
              dayData: data.day.pnl,
              swingData: data.swing.pnl,
            }}
            title="스타일별 수익금"
            value={
              "평균 " +
              convertToNearWon(
                Math.max(data?.scalping?.pnl, data?.day?.pnl, data?.swing?.pnl)
              )
            }
            styleValue={""}
            type="bar"
            formatter={(item) => convertToNearWon(item)}
          />
        </CardWrapper>
      </StyleGrid>
    </StyleWrapper>
  );
};

export default InfoStyleSection;
