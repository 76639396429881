import styled from "styled-components";

const AlphaTag = styled.span`
  display: inline-block;
  white-space: nowrap;
  color: ${(props) => {
    switch (props.type) {
      case "1억":
        return props.is_dark === "dark" ? "#F88460" : "#CF3C0E";
      case "5천":
        return props.is_dark === "dark" ? "#8CC95E" : "#1B6614";
      case "3천":
        return props.is_dark === "dark" ? "#B7A2DE" : "#4A3380";
      case "1천":
        return props.is_dark === "dark" ? "#F2D791" : "#4C371F";
      case "1백":
        return props.is_dark === "dark" ? "#8DCEEB" : "#1A4D80";
      default:
        return props.is_dark === "dark" ? "#A0A0A0" : "#8A8A8A";
    }
  }};

  background-color: ${(props) => {
    switch (props.type) {
      case "1억":
        return props.is_dark === "dark" ? "#382D2B" : "#FADBD7";
      case "5천":
        return props.is_dark === "dark" ? "#2A3329" : "#DBF6D8";
      case "3천":
        return props.is_dark === "dark" ? "#312D40" : "#ECD5F2";
      case "1천":
        return props.is_dark === "dark" ? "#36322A" : "#FFEEC9";
      case "1백":
        return props.is_dark === "dark" ? "#2C333B" : "#D8F0FB";
      default:
        return props.is_dark === "dark" ? "#292929" : "#F2F2F2";
    }
  }};
  margin-left: 5px;
  align-self: center;
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  gap: 10px;
  margin-left: 4px;

  font-family: Pretendard;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
`;

export default AlphaTag;
