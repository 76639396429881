import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import styled, { createGlobalStyle } from "styled-components";
import Footer from "components/Footer/Footer";
import TopNavigation from "components/Navigation/TopNavigation";
import Home from "pages/Home/Home";
import AlphaHome from "pages/Home/AlphaHome";
import TestPage from "pages/Experiment/TestPage";
import TestDetail from "pages/Experiment/TestPageDetail";
import AlphaHotStock from "pages/HotStock/AlphaHotStock";
import HotStock from "pages/HotStock/HotStock";
import LinkAdminPage from "pages/Experiment/LinkAdminPage";
import LinkPage from "pages/Experiment/LinkPage";
import AlphaNote from "pages/TraderDetail/AlphaNote";
import AlphaTraderList from "pages/TraderList/AlphaTraderList";
import PaymentThanksScreen from "pages/Experiment/TestPaymentsThanks";
import PlansPage from "pages/Experiment/Plans";
import TestDaily from "pages/Experiment/DailyCard.js";
import NotFound from "pages/NotFound/NotFound";
import SuccessPage from "pages/Experiment/TestPaymentsThanks";
import TraderDetail from "pages/TraderDetail/TraderDetail";
import "./App.css";
import "./assets/fonts/pretendard-subset.css";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Pretendard;
  }
`;

const queryClient = new QueryClient();

const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #ffffff;
  min-width: 1240px;
`;

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "600px",
    padding: "0",
    border: "none",
    borderRadius: "12px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

function App() {
  // const navigate = useNavigate();
  // const location = useLocation();
  // const [isModalOpen, setIsModalOpen] = useState(false);

  // useEffect(() => {
  //   const visitCount = parseInt(
  //     localStorage.getItem("alphanote_visitCount") || "0",
  //     10
  //   );
  //   const isLoggedIn = !!localStorage.getItem("access_token");

  //   if (!isLoggedIn && location.pathname !== "/login") {
  //     if (visitCount >= 20) {
  //       setIsModalOpen(true);
  //     } else {
  //       localStorage.setItem("alphanote_visitCount", visitCount + 1);
  //     }
  //   }
  // }, [navigate, location]);

  // const closeModal = () => {
  //   setIsModalOpen(false);
  //   navigate("/login");
  // };

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <TopNavigation />
        <main>
          {/* {!isModalOpen && ( */}
          <MainWrapper>
            <Routes>
              <Route path="/" element={<AlphaHome />} />
              <Route path="/trader" element={<AlphaTraderList />} />
              <Route path="/trader/:cuid" element={<TraderDetail />} />
              <Route path="/hotstock/*" element={<AlphaHotStock />} />
              <Route path="/mypage" element={<TraderDetail />} />
              <Route path="/note" element={<AlphaNote />} />
              <Route path="/competition" element={<Home />} />
              <Route path="/exp/test" element={<TestPage />} />
              <Route path="/exp/detail/:cuid" element={<TestDetail />} />
              <Route path="/exp/daily" element={<TestDaily />} />
              <Route path="/exp/hotstock/*" element={<HotStock />} />
              <Route path="/exp/thx/*" element={<PaymentThanksScreen />} />
              <Route path="/confirm" element={<SuccessPage />} />
              <Route path="/success" element={<PaymentThanksScreen />} />
              <Route path="/exp/plans/*" element={<PlansPage />} />
              <Route path="/link/:link" element={<LinkPage />} />
              <Route path="/link" element={<LinkAdminPage />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </MainWrapper>
          {/* )} */}
        </main>
        <Footer />
        {/* {!isModalOpen && <Footer />}
        <ReactModal
          isOpen={isModalOpen}
          // onRequestClose={closeModal}
          contentLabel="Login Required"
          style={modalStyles}
        >
          <LoginRequired closeModal={closeModal} />
        </ReactModal> */}
      </div>
    </QueryClientProvider>
  );
}

export default App;
