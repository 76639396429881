import useHttpClient, { api_v_url } from "hooks/useHttpClient";
import { useQuery } from "react-query";

function useHotStocksQuery() {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["home-get_hotstocks"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/home/get_hotstocks`
      );

      return queryResult?.data;
    },
    enabled: true,
    staleTime: 60 * 60 * 1000,
  });
}

export {
  useHotStocksQuery,
};
