import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const BackgroundWrapper = styled.div`
  background-color: #f0f2f5;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  padding: 40px;
  background: #ffffff;
  border-radius: 12px;
  color: #000;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-family: Pretendard, sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #4caf50;
`;

const Description = styled.p`
  font-family: Pretendard, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  opacity: 0.8;
  margin-bottom: 30px;
`;

const Button = styled.button`
  padding: 12.5px 20px;
  border-radius: 8px;
  background-color: #ff3e61;
  font-family: Pretendard, sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #ff2545;
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 20px;
`;

const OrderDetails = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  text-align: left;
`;

const OrderDetailItem = styled.p`
  font-family: Pretendard, sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

export function SuccessPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [confirmationStatus, setConfirmationStatus] = useState("pending");

  useEffect(() => {
    const requestData = {
      orderId: searchParams.get("orderId"),
      amount: searchParams.get("amount"),
      paymentKey: searchParams.get("paymentKey"),
    };

    async function confirm() {
      try {
        const response = await fetch(
          "https://api.alphanote.io/api/v1b/payments/confirm",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );
        const json = await response.json();
        if (!response.ok) {
          setConfirmationStatus("failed");
          navigate(`/fail?message=${json.message}&code=${json.code}`);
          return;
        }
        setConfirmationStatus("success");
      } catch (error) {
        console.error("Payment confirmation error:", error);
        setConfirmationStatus("failed");
        navigate("/fail?message=Unexpected error occurred");
      }
    }

    confirm();
  }, [navigate, searchParams]);

  const CheckIcon = () => (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#4CAF50" />
      <path
        d="M20 32L28 40L44 24"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  if (confirmationStatus === "pending") {
    return (
      <BackgroundWrapper>
        <ContentWrapper>
          <Title>결제 확인 중...</Title>
          <Description>
            잠시만 기다려 주세요. 결제를 확인하고 있습니다.
          </Description>
        </ContentWrapper>
      </BackgroundWrapper>
    );
  }

  if (confirmationStatus === "failed") {
    return null; // 실패 시 navigate('/fail')에 의해 다른 페이지로 이동됩니다.
  }

  return (
    <BackgroundWrapper>
      <ContentWrapper>
        <IconWrapper>
          <CheckIcon />
        </IconWrapper>
        <Title>결제가 완료되었습니다!</Title>
        <Description>
          저희 서비스를 이용해 주셔서 감사합니다. 이제 모든 기능을 자유롭게
          사용하실 수 있습니다. 상위 1% 트레이더들의 통찰력 있는 전략을
          만나보세요.
        </Description>
        <OrderDetails>
          <OrderDetailItem>
            <strong>주문번호:</strong> {searchParams.get("orderId")}
          </OrderDetailItem>
          <OrderDetailItem>
            <strong>결제 금액:</strong>{" "}
            {Number(searchParams.get("amount")).toLocaleString()}원
          </OrderDetailItem>
          <OrderDetailItem>
            <strong>결제 키:</strong> {searchParams.get("paymentKey")}
          </OrderDetailItem>
        </OrderDetails>
        <Button onClick={() => navigate("/trader")}>
          트레이더 분석 시작하기
        </Button>
      </ContentWrapper>
    </BackgroundWrapper>
  );
}

export default SuccessPage;
