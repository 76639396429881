import { NOTE_STOCK_TYPE, NoteStock } from "components/Card/NoteStock";
import styled from "styled-components";
import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from "./NoteCarouselArrow";

import useEmblaCarousel from "embla-carousel-react";
import { useEffect, useState } from "react";
import { getNoteKey } from "./AlphaDetailHome";
const SectionWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .nc-title-section {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    text-align: left;
    color: #141414;
    display: flex;
    justify-content: space-between;
  }

  .nc-card-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .embla {
    max-width: 1128px;
    --slide-height: 19rem;
    --slide-spacing: 1rem;
    --slide-size: 100%;
    --slide-spacing-sm: 1.6rem;
    --slide-size-sm: 50%;
    --slide-spacing-lg: 19px;
    --slide-size-lg: 267px;
  }

  .embla__viewport {
    overflow: hidden;
  }

  .embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing-lg) * -1);
  }

  .embla__slide {
    min-width: 0;
    flex: 0 0 var(--slide-size-lg);
    padding-left: var(--slide-spacing-lg);
  }

  .embla__buttons {
    align-items: center;
    margin-left: 16px;
    border-collapse: collapse;
    display: flex;

    .left {
      border-radius: 4px 0 0 4px;
    }

    .right {
      border-radius: 0 4px 4px 0;
      border-left: 0;
    }
  }
`;

const NoteCardSection = ({ data, title = "", likeStates, setLikeStates }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ align: "start" });
  const [cardData, setCardData] = useState(data);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const updateIsLiked = (payload, isLiked) => {
    const key = getNoteKey(payload);
    setLikeStates((prev) => ({ ...prev, [key]: isLiked }));
  };

  useEffect(() => {
    setLikeStates({
      ...likeStates,
      ...data.reduce((acc, cur) => {
        const key = getNoteKey(cur);
        acc[key] = cur.like;
        return acc;
      }, {}),
    });
  }, [data]);

  return (
    <SectionWrapper>
      <div className="nc-title-section">
        <div className="nc-title">{title}</div>
        <div className="nc-button">
          <div className="embla__controls">
            <div className="embla__buttons">
              <PrevButton
                onClick={onPrevButtonClick}
                disabled={prevBtnDisabled}
              />
              <NextButton
                onClick={onNextButtonClick}
                disabled={nextBtnDisabled}
              />
            </div>
          </div>
        </div>
      </div>
      <section className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {cardData.map((item, idx) => (
              <div className="embla__slide" key={idx}>
                <NoteStock
                  data={{ ...item, like: likeStates[getNoteKey(item)] }}
                  cardtype={NOTE_STOCK_TYPE.SIMPLE}
                  updateIsLiked={updateIsLiked}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </SectionWrapper>
  );
};

export default NoteCardSection;
