const TRADE_STYLE = [
  { name: "스켈핑", type: "scalping" },
  { name: "데이", type: "day" },
  { name: "스윙", type: "swing" },
];

const TRADE_STYLE_KTYPE = {
  스켈핑: "scalping",
  데이: "day",
  스윙: "swing",
};

const TRADE_STYLE_TYPE = {
  scalping: "스켈핑",
  day: "데이",
  swing: "스윙",
};

const TRADE_RECOMMEND = [
  { name: "트레이더", type: "trader" },
  // { name: "추천", type: "recommend" },
];

const TRADE_RECOMMEND_TYPE = {
  trader: "트레이더",
};

const TRADE_RECOMMEND_KTYPE = {
  트레이더: "trader",
};

const INFO_TITLE = {
  scalping: [
    "스캘핑 승률",
    "평균 수익률",
    "평균 보유 시간",
    "일 평균 스캘핑 매매",
  ],
  day: ["데이 승률", "평균 수익률", "평균 보유 시간", "일 평균 데이 매매"],
  swing: ["스윙 승률", "평균 수익률", "평균 보유 시간", "최고 수익률 종목"],
};

const LEAGUE_LABELS = [
  { name: "1억 리그", type: "1억" },
  { name: "5천 리그", type: "5천" },
  { name: "3천 리그", type: "3천" },
  { name: "1천 리그", type: "1천" },
  { name: "1백 리그", type: "1백" },
];

const LEAGUE_TYPE = {
  "1억": "1억 리그",
  "5천": "5천 리그",
  "3천": "3천 리그",
  "1천": "1천 리그",
  "1백": "1백 리그",
};

const SUBSCRIBE_URL =
  "https://docs.google.com/forms/d/e/1FAIpQLSf0faOZk1EDJ-tDRMDcgOiJ-AHbehRtY1BeUL8MC2KajuOzWw/viewform";

const TELEGRAM_URL = "https://t.me/alpha_note";

const TRADER_TOOLTIP_TEXT = {
  "스캘핑 승률": {
    title: "전체 스캘핑 매매에 대한 승률",
    body: "승률 = (전체 스캘핑 매매 익절 횟수) / (전체 스캘핑 거래 횟수)",
  },
  "평균 수익률": {
    title: "평균 수익률 설명",
    body: "여기에 입력하세요",
  },
  "평균 보유 시간": {
    title: "평균 보유 시간 설명",
    body: "여기에 입력하세요",
  },
  "일 평균 스캘핑 매매": {
    title: "일 평균 스캘핑 매매 설명 ",
    body: "여기에 입력하세요",
  },
  "데이 승률": {
    title: "전체 데이 매매에 대한 승률",
    body: "승률 = (전체 데이 매매 익절 횟수) / (전체 데이 거래 횟수)",
  },
};

export {
  INFO_TITLE,
  LEAGUE_LABELS,
  LEAGUE_TYPE,
  SUBSCRIBE_URL,
  TELEGRAM_URL,
  TRADER_TOOLTIP_TEXT,
  TRADE_STYLE,
  TRADE_STYLE_KTYPE,
  TRADE_STYLE_TYPE,
  TRADE_RECOMMEND,
  TRADE_RECOMMEND_KTYPE,
  TRADE_RECOMMEND_TYPE,
};
