import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import ReactModal from "react-modal";
import ScrollToTop from "utils/ScrollToTop";
import LoginPage from "pages/Login/LoginPage";
import LoginComplete from "pages/Login/LoginCallbackPage";
import PrivacyPolicyPage from "pages/Experiment/Policy";
import TermsOfServicePage from "pages/Experiment/Terms";

ReactModal.setAppElement("#root");

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

// 정적 페이지 감지 함수
const isStaticPage = () => {
  return document.body.classList.contains("static-page");
};

// 경로 정규화 함수
const normalizePath = (path) => {
  return path
    .replace("index.html", "")
    .replace(/\/seo\/?/, "")
    .replace(/\/$/, "");
};

const MainApp = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/login/complete/:access_token"
          element={<LoginComplete />}
        />
        <Route path="/policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms" element={<TermsOfServicePage />} />
        <Route
          path="/seo/*"
          element={
            <Navigate to={normalizePath(window.location.pathname)} replace />
          }
        />
        <Route path="*" element={<App />} />
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
);

if (isStaticPage()) {
  console.log("Static Page Detected");
  const normalizedPath = normalizePath(window.location.pathname);
  console.log("Redirecting to:", normalizedPath);

  if (normalizedPath !== window.location.pathname) {
    window.location.pathname = normalizedPath;
  }
} else {
  root.render(<MainApp />);
}

reportWebVitals();
