import useHttpClient, { api_v_url } from "hooks/useHttpClient";
import { useMutation, useQuery } from "react-query";

function useTraderIsLike({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["user-is_liked_trader", { cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/user/is_liked_trader?cuid=${cuid}`
      );

      return queryResult;
    },
    // enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useTraderAddLike({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useMutation(
    async () => {
      const { data: queryResult } = await httpClient.post(
        `/v1b/user/add_like_trader?cuid=${cuid}`
      );
      return queryResult?.data;
    },
    {
      mutationKey: ["user-add_like_trader", { cuid }],
      // enabled: !!cid,
      staleTime: 60 * 60 * 1000,
    }
  );
}

function useTraderRemoveLike({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useMutation(
    async () => {
      const { data: queryResult } = await httpClient.delete(
        `/v1b/user/remove_like_trader?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    {
      mutationKey: ["user-remove_like_trader", { cuid }],
      // enabled: !!cid,
      staleTime: 60 * 60 * 1000,
    }
  );
}

// function useTraderAddLike({ cuid,  }) {
//   const httpClient = useHttpClient();

//   return useMutation(
//     async () => {
//       const { data: queryResult } = await httpClient.post(
//         `/user/add_like_note?cuid=${cuid}&code=${code}&date=${date}`
//       );

//       return queryResult?.data;
//     },
//     {
//       mutationKey: ["user-add_like_note", { cuid, code, date }],
//       // enabled: !!cid,
//       staleTime: 60 * 60 * 1000,
//     }
//   );
// }

// function useTraderRemoveLike({ cuid, code, date }) {
//   const httpClient = useHttpClient();

//   return useMutation(
//     async () => {
//       const { data: queryResult } = await httpClient.delete(
//         `/user/remove_like_note?cuid=${cuid}&code=${code}&date=${date}`
//       );

//       return queryResult?.data;
//     },
//     {
//       mutationKey: ["user-remove_like_note", { cuid, code, date }],
//       // enabled: !!cid,
//       staleTime: 60 * 60 * 1000,
//     }
//   );
// }

export { useTraderAddLike, useTraderIsLike, useTraderRemoveLike };
