import LikeIcon from "assets/images/ico_like.png";
import LikeActiveIcon from "assets/images/ico_like_avtive.png";
import {
  useTraderAddLike,
  useTraderRemoveLike,
} from "hooks/queries/useTraderLikeQuery";
import { useEffect, useState } from "react";

const TraderLike = ({ status = false, payload, updateIsLiked }) => {
  const [liked, setLiked] = useState(false);

  const {
    mutate: addLikeMutate,
    isSuccess: addLikeSuccess,
    error: addLikeError,
  } = useTraderAddLike({ ...payload });

  const {
    mutate: deleteLikeMutate,
    isSuccess: deleteLikeSuccess,
    error: deleteLikeError,
  } = useTraderRemoveLike({ ...payload });

  const handleLike = async (event) => {
    event.stopPropagation();
    try {
      if (liked) {
        deleteLikeMutate();
      } else {
        addLikeMutate();
      }
    } catch (error) {
      console.error("Error updating like status:", error);
    }
  };

  const gotoLogin = () => {
    const currentPath = window.location.pathname;
    localStorage.setItem("redirectAfterLogin", currentPath);
    window.location.href = "/login";
  };

  useEffect(() => {
    setLiked(status);
  }, [status]);

  useEffect(() => {
    try {
      if (
        addLikeSuccess ||
        (addLikeError && addLikeError?.response?.status === 409)
      ) {
        setLiked(true);
        updateIsLiked(payload, true);
      } else if (addLikeError?.response?.status === 401) {
        gotoLogin();
      }
    } catch (e) {
      console.error("Error updating like status:", e.message);
    }
  }, [addLikeSuccess, addLikeError]);

  useEffect(() => {
    try {
      if (
        deleteLikeSuccess ||
        (deleteLikeError && deleteLikeError?.response?.status === 409)
      ) {
        setLiked(false);
        updateIsLiked(payload, false);
      } else if (deleteLikeError?.response?.status === 401) {
        gotoLogin();
      }
    } catch (e) {
      console.error("Error updating like status:", e.message);
    }
  }, [deleteLikeSuccess, deleteLikeError]);

  return <img src={liked ? LikeActiveIcon : LikeIcon} onClick={handleLike} />;
};

export default TraderLike;
