import { useState } from "react";
import styled from "styled-components";

const ToggleWrapper = styled.div`
  display: flex;
  height: 32px;

  gap: 8px;
  border-radius: 6px;

  margin-top: 12px;
`;

const ToggleItem = styled.div`
  padding: 7.5px 12px;

  border-radius: 4px;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: ${(props) => (props.active === "true" ? "700" : "400")};
  line-height: 16px;
  letter-spacing: 0em;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) =>
    props.active === "true" ? "#000000" : "#F2F4F7"};
  color: ${(props) => (props.active === "true" ? "#FFFFFF" : "#646566")};
  cursor: pointer;

  box-shadow: ${(props) =>
    props.active === "true" ? " 0px 0px 2px 0px #0000001A" : "0"};
`;

const TradeToggle = ({
  setToggleState,
  tabNames = ["리그별 랭킹", "Top 트레이더"],
  toggleState = ["league", "trader"],
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <ToggleWrapper>
      {tabNames.map((item, index) => (
        <ToggleItem
          key={index}
          active={(index === activeIndex).toString()}
          onClick={() => {
            setActiveIndex(index);
            setToggleState(toggleState[index]);
          }}
        >
          {item}
        </ToggleItem>
      ))}
    </ToggleWrapper>
  );
};

export default TradeToggle;
