import React, { useEffect, useState, useCallback } from "react";
import { loadTossPayments } from "@tosspayments/tosspayments-sdk";
import styled from "styled-components";
import RefundPolicyModal from "./RefundPolicyModal";

const clientKey = "test_gck_docs_Ovk5rk1EwkEbP0W43n07xlzm";
const customerKey = "obUjjAlZSKcmUXXn7eUYJ";

const BackgroundWrapper = styled.div`
  background-color: #f0f2f5;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  color: #000;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-family: Pretendard, sans-serif;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`;

const Description = styled.p`
  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.8;
  margin-bottom: 30px;
  text-align: center;
`;

const PlansContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
`;

const PlanCard = styled.div`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 350px;

  ${(props) =>
    props.selected &&
    `
    border-color: #ff3e61;
    box-shadow: 0 0 0 2px #ff3e61;
  `}
`;

const PlanTitle = styled.h2`
  font-family: Pretendard, sans-serif;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const PlanPrice = styled.p`
  font-family: Pretendard, sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`;

const PlanFeatures = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px 0;
`;

const PlanFeature = styled.li`
  font-family: Pretendard, sans-serif;
  font-size: 14px;
  margin-bottom: 8px;
`;

const Button = styled.button`
  padding: 10px 15px;
  border-radius: 8px;
  background-color: #ff3e61;
  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  margin-top: 20px;
  &:hover {
    background-color: #ff2545;
  }
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const ToggleLabel = styled.label`
  font-family: Pretendard, sans-serif;
  font-size: 16px;
  margin: 0 10px;
`;

const ToggleSwitch = styled.input`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  appearance: none;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;
  cursor: pointer;

  &:checked {
    background-color: #2196f3;
  }

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  &:checked:before {
    transform: translateX(26px);
  }
`;

const SelectedPlanInfo = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  font-family: Pretendard, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RefundButton = styled.button`
  background: none;
  border: none;
  color: #2196f3;
  text-decoration: underline;
  cursor: pointer;
  font-family: Pretendard, sans-serif;
  font-size: 14px;
`;

const DailyPrice = styled.p`
  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  height: 20px;
`;

const PaymentSection = styled.div`
  margin-top: 30px;
`;

export function CompactPaymentPlansPage() {
  const [selectedPlan, setSelectedPlan] = useState("light");
  const [amount, setAmount] = useState({
    currency: "KRW",
    value: 19900,
  });
  const [ready, setReady] = useState(false);
  const [widgets, setWidgets] = useState(null);
  const [isAnnual, setIsAnnual] = useState(true);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  const plans = [
    {
      key: "basic",
      name: "Basic",
      price: 0,
      features: [
        "Daily 주도주 조회 기능",
        "MY매매일지 작성 및 관리 기능 - 계좌 1개",
        "커뮤니티 접근 권한",
        "상위 투자자 매매일지 1일 3회 열람",
      ],
    },
    {
      key: "light",
      name: "AlphaNote Light Trader",
      price: 19900,
      features: [
        "Basic의 모든 기능 포함",
        "상위권 투자자 보유 종목 열람 - 1일 3명",
        "상위 투자자 매매일지 열람 - 무제한",
      ],
    },
    {
      key: "pro",
      name: "AlphaNote Professional Trader",
      price: 49900,
      features: [
        "Light Trader의 모든 기능 포함",
        "상위권 투자자 보유 종목 열람 - 무제한",
        "MY매매일지 - 계좌 5개",
        "MY매매일지 AI 피드백 제공",
      ],
    },
  ];

  useEffect(() => {
    async function fetchPaymentWidgets() {
      const tossPayments = await loadTossPayments(clientKey);
      const widgets = tossPayments.widgets({
        customerKey,
      });
      setWidgets(widgets);
    }
    fetchPaymentWidgets();
  }, []);

  useEffect(() => {
    async function renderPaymentWidgets() {
      if (widgets == null) {
        return;
      }
      await widgets.setAmount(amount);
      await Promise.all([
        widgets.renderPaymentMethods({
          selector: "#payment-method",
          variantKey: "DEFAULT",
        }),
        widgets.renderAgreement({
          selector: "#agreement",
          variantKey: "AGREEMENT",
        }),
      ]);
      setReady(true);
    }
    renderPaymentWidgets();
  }, [widgets, amount]);

  useEffect(() => {
    if (widgets == null) {
      return;
    }
    widgets.setAmount(amount);
  }, [widgets, amount]);

  useEffect(() => {
    const selectedPlanData = plans.find((plan) => plan.key === selectedPlan);
    const newPrice = selectedPlanData.price;
    setAmount({
      ...amount,
      value: isAnnual
        ? selectedPlan === "light"
          ? newPrice * 11
          : Math.floor(newPrice * 10)
        : newPrice,
    });
  }, [selectedPlan, isAnnual]);

  const handlePlanSelection = (planKey) => {
    setSelectedPlan(planKey);
  };

  const handleAnnualToggle = () => {
    setIsAnnual(!isAnnual);
  };

  const calculateDailyPrice = (plan) => {
    if (plan.price === 0) return null;
    const totalPrice = isAnnual
      ? plan.key === "light"
        ? plan.price * 11
        : plan.price * 10
      : plan.price;
    const days = isAnnual ? 365 : 30;
    return Math.floor(totalPrice / days);
  };

  const handleRefundModalClose = useCallback(() => {
    setIsRefundModalOpen(false);
  }, []);

  return (
    <BackgroundWrapper>
      <ContentWrapper>
        <Title>AlphaNote 구독 플랜</Title>
        <Description>
          상위 1% 트레이더들의 통찰력 있는 전략으로 당신의 트레이딩을 한 단계
          업그레이드하세요.
        </Description>

        <ToggleWrapper>
          <ToggleLabel>월 결제 (30일)</ToggleLabel>
          <ToggleSwitch
            type="checkbox"
            checked={isAnnual}
            onChange={handleAnnualToggle}
          />
          <ToggleLabel>연 결제 (365일)</ToggleLabel>
        </ToggleWrapper>

        <PlansContainer>
          {plans.map((plan) => (
            <PlanCard key={plan.key} selected={selectedPlan === plan.key}>
              <div>
                <PlanTitle>{plan.name}</PlanTitle>
                <PlanPrice>
                  {plan.price === 0
                    ? "무료"
                    : isAnnual
                    ? `₩${(plan.key === "light"
                        ? plan.price * 11
                        : plan.price * 10
                      ).toLocaleString()} / 년`
                    : `₩${plan.price.toLocaleString()} / 월`}
                </PlanPrice>
                <DailyPrice>
                  {calculateDailyPrice(plan)
                    ? `₩${calculateDailyPrice(plan).toLocaleString()} / 일`
                    : "\u00A0"}{" "}
                  {/* Use non-breaking space to maintain height */}
                </DailyPrice>
                <PlanFeatures>
                  {plan.features.map((feature, index) => (
                    <PlanFeature key={index}>{feature}</PlanFeature>
                  ))}
                </PlanFeatures>
              </div>
              <Button
                onClick={() => handlePlanSelection(plan.key)}
                disabled={plan.key === "basic"}
              >
                {plan.key === "basic" ? "무료로 시작하기" : "선택"}
              </Button>
            </PlanCard>
          ))}
        </PlansContainer>

        <SelectedPlanInfo>
          <p>
            최종 가격:{" "}
            {isAnnual
              ? `₩${(selectedPlan === "light"
                  ? plans.find((plan) => plan.key === selectedPlan).price * 11
                  : plans.find((plan) => plan.key === selectedPlan).price * 10
                ).toLocaleString()}`
              : `₩${plans
                  .find((plan) => plan.key === selectedPlan)
                  .price.toLocaleString()}`}
          </p>
          <RefundButton onClick={() => setIsRefundModalOpen(true)}>
            환불 정책 보기
          </RefundButton>
          <RefundPolicyModal
            isOpen={isRefundModalOpen}
            onClose={handleRefundModalClose}
          />
        </SelectedPlanInfo>

        <PaymentSection>
          <div id="payment-method" />
          <div id="agreement" />

          <Button
            disabled={!ready || selectedPlan === "basic"}
            onClick={async () => {
              try {
                await widgets.requestPayment({
                  orderId: `order_${Date.now()}`,
                  orderName: `${
                    plans.find((plan) => plan.key === selectedPlan).name
                  } 구독`,
                  successUrl: `${window.location.origin}/success`,
                  failUrl: `${window.location.origin}/fail`,
                  customerEmail: "customer@example.com",
                  customerName: "김고객",
                });
              } catch (error) {
                console.error(error);
              }
            }}
          >
            결제하기
          </Button>
        </PaymentSection>
      </ContentWrapper>
      <RefundPolicyModal
        isOpen={isRefundModalOpen}
        onClose={handleRefundModalClose}
      />
    </BackgroundWrapper>
  );
}

export default CompactPaymentPlansPage;
