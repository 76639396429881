import { ReactComponent as CloseIcon } from "assets/images/ico_close_m.svg";
import ReactModal from "react-modal";
import styled from "styled-components";
import { useNotice } from "utils/store";

const customStyles = {
  overlay: {
    zIndex: 1000,
    backgroundColor: "#00000070",
  },
  content: {
    width: "360px",
    inset: "unset",
    margin: "50vh auto",
    padding: 0,
    transform: "translateY(-50%)",
    position: "relative",
  },
};

const ModalWrapper = styled.div`
  border-radius: 8px;
  font-family: Pretendard;
  text-align: left;
`;
const ModalHeader = styled.div`
  padding: 20px 20px 12px;
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.29px;

  svg {
    cursor: pointer;
  }
`;

const ModalBody = styled.div`
  padding: 0 20px 20px;

  font-family: Pretendard;

  font-size: 15px;
  font-weight: 400;
  line-height: 17.9px;
  text-align: left;

  ol {
    padding-left: 18px;
    padding-right: 7px;
  }

  li {
    padding: 4px 0;
  }

  .button {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.71px;
    width: 82px;
    border: 1px solid #e7e9ef;
    border-left: none;
    border-radius: 0 8px 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }
`;

const NoticeModal = ({ isOpen, onCancel }) => {
  const { setNoticeShow } = useNotice((state) => state);

  const handleClickCancel = () => {
    setNoticeShow(true);
    onCancel();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={handleClickCancel}
    >
      <ModalWrapper>
        <ModalHeader>
          <div>AlphaNote</div>
          <CloseIcon onClick={handleClickCancel} />
        </ModalHeader>
        <ModalBody>
          공지사항입니다.
          <ol>
            <li>
              현재 모바일 접속이 지원되지 않아 PC를 통해서만 접속 가능합니다.
            </li>
            <li>
              홈페이지 리뉴얼 작업이 진행 중이며,
              <br />
              <strong>7월 31일</strong>
              에 새로운 모습으로 찾아뵙겠습니다.
            </li>
            <li>
              사용자 여러분의 소중한 피드백은 항상 감사드리며, 더 나은 서비스를
              제공하기 위해 적극 반영하도록 하겠습니다.
            </li>
            <li>
              최신 정보와 소식을 실시간으로 받아보시려면 저희 텔레그램 채널을
              구독해 주세요.
              <a href="https://t.me/alpha_note"><strong>(링크)</strong></a>
            </li>
          </ol>
          여러분의 너그러운 이해와 지속적인 관심에 깊이 감사드립니다.
        </ModalBody>
      </ModalWrapper>
    </ReactModal>
  );
};

export default NoticeModal;
