import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const apiURL = 'https://api.alphanote.io/api/v1b';

const LinkPage = () => {
  const { link } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [debugInfo, setDebugInfo] = useState(null);

  useEffect(() => {
    const getLink = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${apiURL}/link?link=${link}`, {
          maxRedirects: 0,
          validateStatus: function (status) {
            return status >= 200 && status < 400;
          },
        });

        // Log the full response for debugging
        console.log('Full response:', response);

        if (response.status === 302) {
          const redirectUrl = response.data.location;
          if (redirectUrl) {
            console.log('Redirecting to:', redirectUrl);
            window.location.href = redirectUrl;
          } else {
            throw new Error('302 응답을 받았으나 Location 헤더가 없습니다.');
          }
        } else if (response.data && response.data.link) {
          console.log('Redirecting to:', response.data.link);
          window.location.href = response.data.link;
        } else {
          throw new Error('유효한 링크 데이터를 받지 못했습니다.');
        }
      } catch (err) {
        console.error('Error fetching link:', err);
        setError('링크를 가져오는 중 오류가 발생했습니다.');
        
        // Collect debug info
        setDebugInfo({
          errorMessage: err.message,
          responseStatus: err.response?.status,
          responseData: err.response?.data,
          responseHeaders: err.response?.headers,
        });
      } finally {
        setIsLoading(false);
      }
    };

    getLink();
  }, [link]);

  if (isLoading) {
    return <div>연결 중입니다...</div>;
  }

  if (error) {
    return (
      <div>
        <h2>{error}</h2>
        {debugInfo && (
          <div>
            <h3>디버그 정보:</h3>
            <pre>{JSON.stringify(debugInfo, null, 2)}</pre>
          </div>
        )}
      </div>
    );
  }

  return <div>리디렉션 중입니다.</div>;
};

export default LinkPage;