// RefundPolicyModal.js
import React from "react";
import styled from "styled-components";

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const ConfirmButton = styled.button`
  background-color: #2195f3;
  color: white;
  border: none;
  padding: 9px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-family: Pretendard, sans-serif;
  font-size: 15px;
  margin-top: 19px;
`;

const RefundPolicyContent = styled.div`
  text-align: left;
  font-family: Pretendard, sans-serif;
  font-size: 13px;
  line-height: 1.2;

  h1,
  h3 {
    margin-top: 19px;
    margin-bottom: 9px;
  }

  p {
    margin-bottom: 9px;
  }

  ul,
  ol {
    margin-top: 10px;
    padding-left: 20px;

    li {
      margin-bottom: 10px;
    }
  }

  table {
    border-collapse: collapse;
    width: 99%;
    margin-bottom: 9px;
  }

  th,
  td {
    border: 0px solid #ddd;
    padding: 7px;
    text-align: left;
  }

  th {
    background-color: #f1f2f2;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
    text-align: left;
  }
`;
const RefundPolicyModal = ({ isOpen, onClose }) => {
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  if (!isOpen) return null;

  return (
    <Modal onClick={onClose}>
      <ModalContent onClick={handleModalClick}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <RefundPolicyContent>
          <h2>AlphaNote 환불 정책</h2>
          <p>환불정책 시행일: 2024-10-08</p>
          <h3>1. 콘텐츠</h3>
          <table>
            <thead>
              <tr>
                <th>환불 요청일</th>
                <th>환불 금액</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  수강 기간 시작일로부터 7일 이내 & 3개 이하 클래스 영상(샘플
                  영상 제외)을 이용한 경우
                </td>
                <td>결제대금 전액</td>
              </tr>
              {/* 2, 3 6, 8, 9, 12, 14, 15, 15 이후 */}
              <tr>
                <td>수강 기간 또는 수강진도 2/18 경과 전</td>
                <td>결제대금의 16/18</td>
              </tr>
              <tr>
                <td>수강 기간 또는 수강진도 3/18 경과 전</td>
                <td>결제대금의 15/18</td>
              </tr>
              <tr>
                <td>수강 기간 또는 수강진도 6/18 경과 전</td>
                <td>결제대금의 12/18</td>
              </tr>
              <tr>
                <td>수강 기간 또는 수강진도 8/18 경과 전</td>
                <td>결제대금의 10/18</td>
              </tr>
              <tr>
                <td>수강 기간 또는 수강진도 9/18 경과 전</td>
                <td>결제대금의 9/18</td>
              </tr>
              <tr>
                <td>수강 기간 또는 수강진도 12/18 경과 전</td>
                <td>결제대금의 6/18</td>
              </tr>
              <tr>
                <td>수강 기간 또는 수강진도 14/18 경과 전</td>
                <td>결제대금의 4/18</td>
              </tr>
              <tr>
                <td>수강 기간 또는 수강진도 15/18 경과 전</td>
                <td>결제대금의 3/18</td>
              </tr>
              <tr>
                <td>수강 기간 또는 수강진도 15/18 경과 후</td> <td> 없음 </td>
              </tr>
            </tbody>
          </table>
          <p>
            ■ 수강 기간이란 회사가 회원에게 유상으로 제공하는 서비스
            이용기간으로서 30일을 의미합니다. 단, 개별 서비스에 따라 30일 미만의
            수강 기간이 제공될 수 있습니다.
          </p>
          <p>
            ■ 수강 기간은 결제일부터 시작합니다. 단, 결제일 당시 서비스를 이용할
            수 없는 경우 서비스의 이용 가능일부터 시작합니다.
          </p>
          <p>
            ■ 수강진도란 총 서비스 이용 가능 횟수 중 기 이용한 횟수의 비율을
            의미합니다. 단, 서비스의 일부 기능 사용 시 이용으로 간주합니다.
          </p>
          <p>
            ■ 환불 요청일 기준 수강 기간과 수강진도 중 높은 값을 적용하여 환불
            금액을 산정합니다.
          </p>
          <p>
            ■ 회원이 서비스와 함께 제공되는 자료 또는 정보를 다운로드하는 경우
            해당 자료 또는 정보가 포함된 서비스를 이용한 것으로 간주합니다.
          </p>
          <h3>
            2. 정기구독서비스 (AlphaNote Light Trader, AlphaNote Professional
            Trader)
          </h3>
          <p>
            정기구독서비스 해지 시 해지일이 속하는 이용기간(월)까지 서비스를
            이용할 수 있습니다. 다만, 결제일부터 7일 이내에 콘텐츠를 이용(콘텐츠
            또는 자료의 다운로드 포함)하지 않는 경우에 한하여 결제대금 전액은
            환불됩니다.
          </p>
          <p>
            또한, 1월을 초과하는 정기구독서비스 이용을 결제한 경우 결제대금에서
            해지신청일을 포함하는 이용기간(월)에 비례하는 월간 구독서비스
            이용대금을 공제한 잔액의 100의 90을 환불합니다.
          </p>
          <h4>예시:</h4>
          <ul>
            <li>
              AlphaNote Light Trader 3개월 구독(59,700원) 후 1개월 15일 사용 후
              해지 요청 시
              <br />
              이용 금액: 19,900원 (1개월) + 9,950원 (15일) = 29,850원
              <br />
              잔여 금액: 59,700원 - 29,850원 = 29,850원
              <br />
              환불 금액: 29,850원 * 0.9 = 26,865원
            </li>
            <li>
              AlphaNote Professional Trader 6개월 구독(299,400원) 후 2개월 10일
              사용 후 해지 요청 시
              <br />
              이용 금액: 99,800원 (2개월) + 16,633원 (10일) = 116,433원
              <br />
              잔여 금액: 299,400원 - 116,433원 = 182,967원
              <br />
              환불 금액: 182,967원 * 0.9 = 164,670원
            </li>
          </ul>
          <p>
            다만, 외부 온라인/오프라인 상점 또는 IAP(In-App Purchase)에서
            정기구독서비스 이용권을 구입한 경우 환불은 구입처의 환불정책에
            의합니다.
          </p>
          <h3>3. 기타 사항</h3>
          <p>
            ■ 회사가 마케팅, 이벤트 등과 관련하여 무상으로 부여하는 추가
            이용기간(보너스기간)은 환불에 영향을 미치지 아니합니다.
          </p>
          <p>
            ■ 회사는 환불 시 금융거래수수료, 제세공과금 등을 공제할 수 있습니다.
          </p>
          <p>
            ■ 회사는 회원이 관계 법령 또는 이용약관 등을 위반한 경우 이용약관 및
            정책에 따라 환불을 거부할 수 있습니다.
          </p>
          <p>
            ■ 결합 상품의 경우 각 구성 상품(용역)의 환불 조건에 의하되 개별 상품
            안내페이지를 통하여 고지합니다.
          </p>
          <h3>4. 환불 제외 사항</h3>
          <p>다음의 경우에는 환불이 불가능합니다:</p>
          <ul>
            <li>서비스의 악용이 명백한 경우</li>
            <li>관계 법령 또는 이용약관 등을 위반한 경우</li>
            <li>
              그 밖에 이용약관 및 정책에서 정하는 환불 제외 사유에 해당하는 경우
            </li>
          </ul>
          <h3>5. 환불 절차</h3>
          <ol>
            <li>고객센터 이메일(contact@arpa.co.kr)로 환불 요청</li>
            <li>
              환불 요청 시 다음 자료 첨부:
              <ul>
                <li>이용자 본인 확인을 위한 신분증 사본(주민등록번호 제외)</li>
                <li>통장사본</li>
                <li>주소를 알 수 있는 자료</li>
              </ul>
            </li>
            <li>회사의 환불 사유 및 이용 내역 확인</li>
            <li>환불 금액 산정 및 승인</li>
            <li>승인 후 7일 이내 결제 수단으로 환불 처리</li>
          </ol>
          <h3>6. 정책의 개정</h3>
          <p>
            ■ 회사는 「약관의 규제에 관한 법률」, 「전자상거래 등에서의
            소비자보호에 관한 법률」 등 관계 법령을 위배하지 아니하는 범위
            내에서 이 정책을 개정할 수 있습니다.
          </p>
          <p>
            ■ 회사가 이 정책을 개정하는 경우 회원에게 주요 개정내용과 시행일
            등을 명시하여 시행일로부터 7일 전에 전자적 방법으로
            알려드리겠습니다. 다만, 회원에게 불리한 내용이 포함되는 경우
            시행일로부터 30일 전에 알려드리겠습니다.
          </p>
          <p>
            AlphaNote 서비스와 관련하여 궁금하신 사항이 있으시면
            고객센터(대표번호: 010-2605-2193 / 평일 10:00~17:00)로 문의 주시기
            바랍니다.
          </p>
        </RefundPolicyContent>
        <ConfirmButton onClick={onClose}>확인</ConfirmButton>
      </ModalContent>
    </Modal>
  );
};

export default RefundPolicyModal;
