import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { formatDateTime, formatDateToYMD } from "utils/utils";
import moment from 'moment';

const apiURL = 'https://api.alphanote.io/api/v1b';

const styleLoadingOverlay = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.7)',
  backdropFilter: 'blur(2px)',
  zIndex: 1000,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const TestPage = () => {
  const [userId, setUserId] = useState('');
  const [history, setHistory] = useState([]);
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [historyStart, setHistoryStart] = useState(null);
  const [historyEnd, setHistoryEnd] = useState(null);
  const [analysisStart, setAnalysisStart] = useState('');
  const [analysisEnd, setAnalysisEnd] = useState('');

  // get jwt token from local storage
  const token = localStorage.getItem('access_token');

  // get history with setHistory function when open
  useEffect(() => {
    getMyStatus();
  }, []);

  // when userId changes, set analysisStart and analysisEnd to empty string
  // to prevent using old values
  React.useEffect(() => {
    setAnalysisStart('');
    setAnalysisEnd('');
  }, [userId]);





  const getMyStatus = async () => {
    try {
      setLoading(true);

      const response = await axios.get(`${apiURL}/my/history`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      });
      var data = response.data;
      // upload_date, first_transaction, last_transaction multiple by 1000
      data.reverse().forEach(element => {
        element.upload_date = element.upload_date * 1000;
        element.first_transaction = element.first_transaction * 1000;
        element.last_transaction = element.last_transaction * 1000;
      });

      setHistory(data);
      console.log(

       Math.min(...data.map((item) => item.first_transaction))
      )

      const minFirstTransaction = Math.min(...data.map((item) => item.first_transaction));
      const maxLastTransaction = Math.max(...data.map((item) => item.last_transaction));

      setHistoryStart(minFirstTransaction);
      setHistoryEnd(maxLastTransaction);

      // 상태 업데이트 함수가 비동기적으로 작동하기 때문에, 직접 계산된 값을 사용합니다.
      if (analysisStart === '') {
        setAnalysisStart(moment(minFirstTransaction).format('YYYY-MM-DD'));
        setAnalysisEnd(moment(maxLastTransaction).format('YYYY-MM-DD'));
      }


    } catch (error) {
      console.error(error);
      setHistory([]);
    } finally {
      setLoading(false);
    }

    try {
      setLoading(true);
      const response = await axios.get(
        `${apiURL}/my/report`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      setReport(response.data);
    } catch (error) {
      console.error(error);
      setReport([]);
    } finally {
      setLoading(false);
    }
  };

  const generateReport = async () => {
    try {
      setLoading(true);
      console.log(
        `${apiURL}/my/report?start=${analysisStart}&end=${analysisEnd}`
      )
      console.log(
        `token : ${localStorage.getItem('access_token')}`
      )
      const response = await axios.post(
        `${apiURL}/my/report?start=${analysisStart}&end=${analysisEnd}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      getMyStatus();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      setUploading(true);
      var response = await axios.post(`${apiURL}/my/history`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        },
      });
      
      console.log(response);

      if (response.status === 200) {
        console.log('File uploaded successfully!');
        getMyStatus();
      }
      else {
        console.log('Failed to upload file.');
      }

    } catch (error) {
      console.error(error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <h1>History Lookup</h1>
      <h4>token : {token}</h4>
{history.length > 0 && (
  <div>
    <input
      type="date"
      min={moment(historyStart).format('YYYY-MM-DD')}
      max={moment(historyEnd).format('YYYY-MM-DD')}
      value={moment(analysisStart).format('YYYY-MM-DD')} // analysisStart 상태를 value로 설정
      onChange={(e) => setAnalysisStart(e.target.value)}
    />
    ~
    <input
      type="date"
      min={moment(historyStart).format('YYYY-MM-DD')}
      max={moment(historyEnd).format('YYYY-MM-DD')}
      value={moment(analysisEnd).format('YYYY-MM-DD')} // analysisEnd 상태를 value로 설정
      onChange={(e) => setAnalysisEnd(e.target.value)}
    />
    <button onClick={generateReport}> Generate Analysis </button>
  </div>
)}

      <h2>Report</h2>
      {report.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {report.map((item) => (
              <tr key={item.code}>
                <td>{item.code}</td>
                <td>{item.start}</td>
                <td>{item.end}</td>
                <td>
                  {item.status === 'completed' ? (
                    <button onClick={() => window.location.href = `/exp/detail/알파노트_${userId}_${item.code}`}>Detail</button>
                  ) : (
                    item.status
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      ) : (
        <p>No history found.</p>
      )}

      <h2>Upload File</h2>
      <input type="file" onChange={(e) => setFile(e.target.files[0])} />
      <button onClick={uploadFile}>Upload</button>

      {uploading && <p>Uploading...</p>}

      <h2>History</h2>
      {loading || uploading ? (
        <div style={styleLoadingOverlay}>
          <p>Loading...</p>
        </div>
      ) : history.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>JID</th>
              <th>Upload Date</th>
              <th>First</th>
              <th>Last</th>
              <th>Days</th>
              <th>N Stocks</th>
              <th>N Transactions</th>
            </tr>
          </thead>
          <tbody>
            {history.map((item) => (
              <tr key={item._id}>
                <td>{item._id}</td>
                <td>{formatDateToYMD(item.upload_date)}</td>
                <td>{formatDateToYMD(item.first_transaction)}</td>
                <td>{formatDateToYMD(item.last_transaction)}</td>
                <td>{((item.last_transaction - item.first_transaction) / 86400000).toFixed(0) + 1}</td>
                <td>{item.n_stock}</td>
                <td>{item.n_trx}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No history found.</p>
      )}

    </div>
  );
};

export default TestPage;

// const TestPage = () => {
//   const [selectedFile, setSelectedFile] = useState(null);

//   const handleFileChange = (event) => {
//     setSelectedFile(event.target.files[0]);
//   };

//   return (
//     <div>
//       <h1>File Select Input Page</h1>
//       <input type="file" onChange={handleFileChange} />
//       {selectedFile && <p>Selected file: {selectedFile.name}</p>}
//       <button onClick={() => {}}>업로드 버튼</button>
//     </div>
//   );
// };

// export default TestPage;
